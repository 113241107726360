import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import './Summary.css';

import { Link, useNavigate } from 'react-router-dom';
import { Border, ChevronRight, PlugFill, Plus, Trash } from "react-bootstrap-icons";

// components
import * as serverURL from '../../../config/Constants'


// providers
import { useResumeStep } from '../../../context/resume-step/ResumeStepContext';
import { ValidationContext } from '../../../context/validation/ValidationContext';
import { useACVResume } from '../../../context/resume/ResumeContext';

// MUI library
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';



const Sammary = () => {
    const navigate = useNavigate();
    const { setResumeStep } = useResumeStep();
    const { validate, setFormErrorEmpty, formError, setFormError } = useContext(ValidationContext);
    const { acvResume, setAcvResume } = useACVResume();
    const [dailogOpen, setDailogOpen] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [error, setError] = useState({});
    const [texts, setTexts] = useState(['To seek and maintain full-time position that offers professional challenges utilizing interpersonal skills, excellent time management and problem-solving skills.', 'To seek and maintain full-time position that offers professional challenges utilizing interpersonal skills, excellent time management and problem-solving skills. Hardworking and passionate job seeker with strong organizational skills eager to secure entry-level [Job Title] position. Ready to help team achieve company goals.', 'To seek and maintain full-time position that offers professional challenges utilizing interpersonal skills, excellent time management and problem-solving skills. Hardworking and passionate job seeker with strong organizational skills eager to secure entry-level [Job Title] position. Ready to help team achieve company goals. Detail-oriented team player with strong organizational skills. Ability to handle multiple projects simultaneously with a high degree of accuracy.']);
    const [selectedText, setSelectedText] = useState('');


    useEffect(() => {
        if (acvResume) {
            axios.get(serverURL.SERVER_URL + 'summary/' + acvResume.resume_id, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': '$2y$10$e6tbgQSbvmC6X7us57XsIeuBZT7eZYqiu/zDTKjKNX4KL9wQEOvEG'
                }
            }).then((result) => {
                const data = result.data;
                setSelectedText(data.summary);
            })
        }
    }, [])

    // close the confirmation dialog 
    const handleDailogClose = () => {
        setDailogOpen(false);
    };


    // for button
    const handleAddTextToTextbox = (text, event) => {
        event.preventDefault();
        setSelectedText(text);
    };

    // for dropdown on small screen
    const handleChange = (event) => {
        setSelectedText(event.target.value);
    };

    const cehckSummary = () => {
        if (selectedText.length < 1) {
            setDailogOpen(true);
        } else {
            nextFinalize();
        }
    };
    const nextFinalize = () => {

        // if user not add its summary
        if (selectedText.length <= 0) {
            setResumeStep(8);
            navigate('/resume/finalize')
        }
        setDailogOpen(false);
        setBackdropOpen(true);
        setFormErrorEmpty();
        try {
            axios.post(serverURL.SERVER_URL + 'summary/' + acvResume.resume_id, { summary: selectedText }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then((result) => {
                const data = result.data;
                if (result.status === 200) {
                    setResumeStep(8);
                    navigate('/resume/finalize')
                }
            }).catch(function (error) {
                setBackdropOpen(false);
                setError(error.message)
            })
        } catch (error) {
            setBackdropOpen(false);
        }

    };
    return (
        <>
            <div className="main-content float-end col-lg-10 col-12">
                <div className="title">
                    Briefly tell us about your background
                </div>
                <div className="sub-title">
                    Choose from our pre-written examples below or write your own.
                </div>
                <Container className='mt-4' fixed>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={7} className='d-block d-lg-none'>
                            <TextField
                                className='col-12'
                                id="outlined-select-currency-native"
                                select
                                label="Summaries"
                                SelectProps={{
                                    native: true,
                                }}
                                helperText="Please select a summary"
                                onChange={handleChange}
                            >
                                {texts.map((text, index) => (
                                    <option key={index}>
                                        {text}
                                    </option>
                                ))}

                            </TextField>
                        </Grid>
                        <Grid item xs={6} className='text-start d-none d-lg-block'>
                            <ul className="list-group">
                                {texts.map((text, index) => (
                                    <div className="list-group-item d-flex align-items-center" key={index} onClick={(event) => handleAddTextToTextbox(text, event)}>
                                        <button className='btn btn-add-profession text-center me-3' style={{ height: '40px', padding: '0px 5px' }}>
                                            <Plus className='icon' />
                                        </button>
                                        <span>
                                            {text}
                                        </span>
                                    </div>
                                ))}
                            </ul>
                        </Grid>
                        <Grid item xs={12} md={6} className='text-end'>
                            <TextField
                                className={`col-12`}
                                required
                                id="outlined-required"
                                label="Professional Summary:"
                                multiline
                                rows={8}
                                value={selectedText}
                                onChange={(e) => setSelectedText(e.target.value)}
                                name='message'
                            />
                        </Grid>
                        <Grid item xs={12} className='text-end' >
                            <Link to={'/resume/references'} onClick={() => setResumeStep(6)} className='btn btn-outline-app-primary btn-circle me-1'> Previous</Link>
                            <button className='btn btn-dark btn-circle' onClick={cehckSummary}> Next : Finalize</button>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            {/* confirm place order dialog */}
            <Dialog
                open={dailogOpen}
                onClose={handleDailogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Warning, you didn't add your summary!"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleDailogClose}>Add</Button>
                    <Button onClick={nextFinalize}>
                        Containue
                    </Button>
                </DialogActions>
            </Dialog>
            {/* end confirm place order dialog */}

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default Sammary