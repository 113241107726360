import React, { useContext, useState, useEffect } from 'react';
import './PersonalInfo.css';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Border, ChevronRight } from "react-bootstrap-icons";

// components
import * as serverURL from '../../../config/Constants'

// images
import noImage from '../../../assets/img/rect-female-no-image.jpg'

// providers
import { useResumeStep } from '../../../context/resume-step/ResumeStepContext';
import { ValidationContext } from '../../../context/validation/ValidationContext';
import { useACVResume } from '../../../context/resume/ResumeContext';


// MUI library
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const PersonalInfo = () => {

    // import validtaion context params
    const { validate, setFormErrorEmpty, formError, setFormError } = useContext(ValidationContext);
    const { acvResume, setAcvResume } = useACVResume();

    const { setResumeStep } = useResumeStep();
    const navigate = useNavigate();
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);

    // from input
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [profession, setProfession] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [profileImage, setProfileImage] = useState(null);


    useEffect(() => {
        if (acvResume) {
            axios.get(serverURL.SERVER_URL + 'personal-info/' + acvResume.resume_id, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': '$2y$10$e6tbgQSbvmC6X7us57XsIeuBZT7eZYqiu/zDTKjKNX4KL9wQEOvEG'
                }
            }).then((result) => {
                const data = result.data;
                setFirstName(data.resume.first_name || '');
                setLastName(data.resume.last_name || '');
                setProfession(data.resume.profession || '');
                setCity(data.resume.city || '');
                setCountry(data.resume.country || '');
                setPostalCode(data.resume.postal_code || '');
                setPhone(data.resume.phone || '');
            setEmail(data.resume.email || '');
                setProfileImage(serverURL.Resume_IMG_URL + data.resume.img_path);
            })
        }

    }, [])

    // preview profile pic
    const handleChangeImage = (e) => {
        setProfileImage({ [e.target.name]: URL.createObjectURL(e.target.files[0]) }.profile_pic)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let fd = new FormData();
        fd.append("first_name", firstName);
        fd.append("last_name", lastName);
        fd.append("profession", profession);
        fd.append("city", city);
        fd.append("country", country);
        fd.append("postal_code", postalCode);
        fd.append("phone", phone);
        fd.append("email", email);
        fd.append("user_id", '');
        fd.append("img_path", data.get('profile_pic'));

        if (validate('input', 'First name', firstName) == -1) {
            return
        }
        if (validate('input', 'Surname', lastName) == -1) {
            return
        }
        if (validate('input', 'Phone', phone) == -1) {
            return
        }
        if (validate('email', 'Email', email) == -1) {
            return
        }

        setFormErrorEmpty();
        setBackdropOpen(true);
        // update if the resume is exist
        if (acvResume) {
            try {
                axios.post(serverURL.SERVER_URL + 'personal-info-update/' + acvResume.resume_id, fd, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }).then((result) => {
                    const data = result.data;
                    if (result.status === 200) {
                        setResumeStep(2);
                        navigate('/resume/work-history')
                    }
                    setBackdropOpen(false)
                }).catch(function (error) {
                    setBackdropOpen(false)
                    setError(error.message)
                })
            } catch (error) {
                setBackdropOpen(false)
            }
        } else {
            try {
                axios.post(serverURL.SERVER_URL + 'personal-info-store', fd, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }).then((result) => {
                    const data = result.data;
                    if (result.status === 200) {
                        setAcvResume(data.resume.id)
                        setResumeStep(2);
                        navigate('/resume/work-history')
                    }
                    setBackdropOpen(false)
                }).catch(function (error) {
                    setBackdropOpen(false)
                    setError(error.message)
                })
            } catch (error) {
                setBackdropOpen(false)
            }
        }

    }
    return (
        <>
            <div className="main-content float-end col-lg-10 col-12">
                <div className="title">
                    What’s the best way for employers to contact you?
                </div>
                <div className="sub-title">
                    We suggest including an email and phone number.
                </div>
                <form onSubmit={handleSubmit} method='post' encType="multipart/form-data">

                    <div className="row justify-content-center">
                        <div className="col-lg-3 col-md-3 col-sm-10 col-xs-10" style={{ paddingTop: '50px' }}>
                            <div className="col-12 mb-3 text-center">
                                <img src={profileImage != null ? profileImage : noImage} alt="" style={{ height: '100px' }} />
                            </div>
                            <div className="col-12 text-center">
                                <Button component="label" variant="contained" size="small" className='button-dark'>
                                    Upload Photo
                                    <VisuallyHiddenInput type="file" name="profile_pic" onChange={handleChangeImage} />
                                </Button>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className=" mb-4">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="mb-4">
                                                <label htmlFor="first_name" className="form-label">First Name *</label>
                                                <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="Type here" className={`form-control ${formError.type == 'First name' && 'error'}`} id="first_name" />
                                                {
                                                    formError.type == 'First name' && (
                                                        <label className="text-danger">{formError.error}</label>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-4">
                                                <label htmlFor="last_name" className="form-label">Surname *</label>
                                                <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Type here" className={`form-control ${formError.type == 'Surname' && 'error'}`} id="last_name" />
                                                {
                                                    formError.type == 'Surname' && (
                                                        <label className="text-danger">{formError.error}</label>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="profession" className="form-label">Profession</label>
                                        <input type="text" value={profession} onChange={(e) => setProfession(e.target.value)} placeholder="Type here" className="form-control" id="profession" />
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="mb-4">
                                                <label htmlFor="product_name" className="form-label">City/Municipality</label>
                                                <input type="text" value={city} onChange={(e) => setCity(e.target.value)} placeholder="Type here" className="form-control" id="product_name" />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="mb-4">
                                                <label htmlFor="product_name" className="form-label">Country</label>
                                                <input type="text" value={country} onChange={(e) => setCountry(e.target.value)} placeholder="Type here" className="form-control" id="product_name" />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="mb-4">
                                                <label htmlFor="product_name" className="form-label">Postal Code</label>
                                                <input type="text" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} placeholder="Type here" className="form-control" id="product_name" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="mb-4">
                                                <label htmlFor="product_name" className="form-label">Phone</label>
                                                <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Type here" className={`form-control ${formError.type == 'Phone' && 'error'}`} id="product_name" />
                                                {
                                                    formError.type == 'Phone' && (
                                                        <label className="text-danger">{formError.error}</label>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-4">
                                                <label htmlFor="product_name" className="form-label">Email</label>
                                                <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Type here" className={`form-control ${formError.type == 'Email' && 'error'}`} id="product_name" />
                                                {
                                                    formError.type == 'Email' && (
                                                        <label className="text-danger">{formError.error}</label>
                                                    )
                                                }
                                            </div>
                                        </div>

                                        <div className="col-12 text-end">
                                            <button className='btn btn-dark btn-circle' type='submit'> Next : Work History</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>


            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default PersonalInfo