import React, { useContext } from 'react';
import './GetStart.css';

import { Link } from 'react-router-dom';
import { ChevronRight } from "react-bootstrap-icons";


import { ResProfileContext } from '../../../context/restaurant/ProfileContext';


// MUI library
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const GetStart = () => {

    // import profile context
    const { restaurantProfile } = useContext(ResProfileContext);

    return (
        <>
            <div className="container">
                <div className="col-12 row">
                    <div className="col-12 my-5">
                        <h2 className='text-center'>How do you want to build your resume?</h2>
                    </div>
                    <div className="col-6">
                        <a href="#" className="card card-clickable" onClick={(e) => {e.preventDefault()}} >
                            <div className="card-body">
                                <h2 className="h5 card-title">Create a new resume</h2>
                                <p className="card-text">We will help you create a resume step-by-step.</p>
                            </div>
                        </a>

                    </div>
                    <div className="col-6">
                        <a href="#" className="card card-clickable" onClick={(e) => {e.preventDefault()}}>
                            <div className="card-body">
                                <h2 className="h5 card-title">Create a new cover letter</h2>
                                <p className="card-text">We'll reformat it and fill in your infromation so you don't have to.</p>
                            </div>
                        </a>
                    </div>

                    <div className="col-12 my-4"></div>
                    
                    <div className="col-12 text-end px-4">
                        <Link to={"/resume/personal-info"} className={'btn btn-dark'}>
                            Next <ChevronRight/>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GetStart