export const NO_IMG_URL = 'https://admin.aliencv.com/images/menu/'
export const RESUME_IMG_URL = 'https://admin.aliencv.com/api/images/resumes/'
export const FOOD_IMG_URL = 'https://admin.aliencv.com/images/menu/'
export const CATEGORY_IMG_URL = 'https://admin.aliencv.com/images/category/'
export const SUB_CATEGORY_IMG_URL = 'https://admin.aliencv.com/images/sub_category/'
export const CAROUSEL_IMG_URL = 'https://admin.aliencv.com/images/carousel/'
export const Resume_IMG_URL = 'https://admin.aliencv.com/images/resumes/'
export const SERVER_URL = 'https://admin.aliencv.com/api/'
export const LOCAL_SERVER_URL = 'http://localhost:8000/api/'

// https://admin.aliencv.com/
// http://localhost:8000