import React, { useContext } from 'react';
import './About.css';

import { Link } from 'react-router-dom';
import { ChevronRight } from "react-bootstrap-icons";


import { ResProfileContext } from '../../context/restaurant/ProfileContext';

import iconCheck from '../../assets-website/img/icons/check.png';
import CVIcon from '../../assets-website/img/core-img/cv.png';
import imgD1 from '../../assets-website/img/icons/d1.png';
import imgD2 from '../../assets-website/img/icons/d2.png';
import imgD3 from '../../assets-website/img/icons/d3.png';
import imgCustom from '../../assets-website/img/core-img/custom.png';

const About = () => {

    // import profile context
    const { restaurantProfile } = useContext(ResProfileContext);

    return (
        <>
            {/* ##### Welcome Area Start #####  */}
            <div className="breadcumb-area clearfix dzsparallaxer auto-init" data-options='{direction: "normal"}'>
                <div className="divimage dzsparallaxer--target" style={{ width: '101%', height: '130%', backgroundImage: 'img/bg-img/bg-2.jpg' }}></div>
                {/* breadcumb content  */}
                <div className="breadcumb-content">
                    <div className="container h-100">
                        <div className="row h-100 align-items-center">
                            <div className="col-12">
                                <nav aria-label="breadcrumb" className="breadcumb--con text-center">
                                    <h2 className="w-text title wow fadeInUp text-dark" data-wow-delay="0.2s" >About us</h2>
                                    <ol className="breadcrumb justify-content-center wow fadeInUp" data-wow-delay="0.4s">
                                        <li className="breadcrumb-item "><a href="#" className='text-dark'>Home</a></li>
                                        <li className="breadcrumb-item active text-dark" aria-current="page">About us</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ##### Welcome Area End #####   */}

            {/* About Us Area Start  */}
            <section className="about-us-area section-padding-100 clearfix">
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-12 col-lg-6">
                            <div className="who-we-contant">
                                <div className="dream-dots">
                                    <span></span><span></span><span></span><span></span><span></span><span></span><span></span>
                                </div>
                                <h4 className="bold">We Deliver The Best</h4>
                                <div className="list-wrap align-items-center">
                                    <div className="row">

                                        <div className="col-md-12">
                                            <div className="side-feature-list-item">
                                                <img src={iconCheck} className="check-mark-icon" alt="" />
                                                <div className="foot-c-info">Proven CV Templates to increase Hiring Chance</div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="side-feature-list-item">
                                                <img src={iconCheck} className="check-mark-icon" alt="" />
                                                <div className="foot-c-info">Creative and Clean Templates Design</div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="side-feature-list-item">
                                                <img src={iconCheck} className="check-mark-icon" alt="" />
                                                <div className="foot-c-info">Easy and Intuitive Online CV Builder</div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="side-feature-list-item">
                                                <img src={iconCheck} className="check-mark-icon" alt="" />
                                                <div className="foot-c-info">Free to use. Developed by hiring professionals.</div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="side-feature-list-item">
                                                <img src={iconCheck} className="check-mark-icon" alt="" />
                                                <div className="foot-c-info">Fast Easy CV and Resume Formatting</div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="side-feature-list-item">
                                                <img src={iconCheck} className="check-mark-icon" alt="" />
                                                <div className="foot-c-info">Recruiter Approved Phrases.</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-6">
                            <div className="welcome-meter wow fadeInUp mt-s" data-wow-delay="0.3s">
                                <img src={CVIcon} className="center-block" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* About Us Area End  */}

            <section className="demo-video feat section-padding-100 bub-left">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="services-block-four">
                                <div className="inner-box">
                                    <div className="icon-img-box">
                                        <img src={imgD1} alt="" />
                                    </div>
                                    <h3><a href="#">User-Friendly Interface</a></h3>
                                    <div className="text">Our online CV builder website features an intuitive and user-friendly interface, making it easy for users to create professional resumes with just a few clicks.</div>

                                </div>
                            </div>
                            <div className="services-block-four">
                                <div className="inner-box">
                                    <div className="icon-img-box">
                                        <img src={imgD2} alt="" />
                                    </div>
                                    <h3><a href="#"> Customizable Templates</a></h3>
                                    <div className="text">Choose from a wide range of customizable templates to create a unique and visually appealing CV that highlights your skills and experience.</div>

                                </div>
                            </div>
                            <div className="services-block-four" style={{ marginBottom: 0 }}>
                                <div className="inner-box">
                                    <div className="icon-img-box">
                                        <img src={imgD3} alt="" />
                                    </div>
                                    <h3><a href="#">Export Options</a></h3>
                                    <div className="text">Easily export your completed CV in multiple formats, such as PDF.</div>

                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="who-we-contant mt-s">
                                <div className="dream-dots">
                                    <span></span><span></span><span></span><span></span><span></span><span></span><span></span>
                                </div>
                                <h4>Why Choose Our Platform?</h4>
                                <p className='text-secondary '>
                                    As a reputable and trustworthy website for creating professional resumes, we have a team of experienced professionals who can help you craft an outstanding and engaging CV.
                                </p>
                                <p className='text-secondary '> We use modern design and unique templates to showcase amazing resumes that allow you to easily demonstrate your attractiveness and professionalism.</p>
                                <Link className="btn dream-btn mt-30" to={'/get-start'}>lets build your cv</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

             {/* Area start  */}
             <section className="container section-padding-0-100">
                <div className="subscribe">
                    <div className="row align-items-center  text-right" >
                        <img src={imgCustom} alt="" className="custom" style={{ marginLeft: '-8px', marginTop: '1px' }} />
                        <div className="col-lg-5 offset-lg-3 col-md-9 col-xs-12">
                            <h2 className="bold mb-0">Do you Need a Complete Custom CV Template?</h2>
                        </div>
                        <div className="col-lg-3 offset-lg-1 col-md-3 col-sm-12 text-right ">
                            <Link to={'/contact'} className="button mt-s">Send a Request</Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* Area End  */}
        </>
    )
}

export default About