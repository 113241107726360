import React from 'react'


const Footer = ({ children }) => {
    return (
        <>
        
           
        </>
    )
}

export default Footer