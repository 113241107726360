import React from "react";
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Image,
    Font,
    Svg,
    Circle
} from "@react-pdf/renderer";

import RobotoBold from '../../../assets/fonts/Roboto-Bold.ttf'
import * as serverURL from '../../../config/Constants'

Font.register({ family: 'Roboto', src: RobotoBold, fontWeight: 'bold' });

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 20,
        fontFamily: 'Helvetica',
        paddingLeft: 30
    },
    section_header: {
        marginBottom: 10,
        // borderBottomColor: '#888',
        // borderBottomWidth: 1,
        paddingBottom: 10,
        textAlign: 'center'
    },
    section: {
        marginBottom: 10,
        borderTopColor: '#888',
        borderTopWidth: 1,
        paddingTop: 10,
    },
    header: {
        fontSize: 24,
        marginBottom: 10,
        color: '#333',
    },
    contact_name: {
        fontSize: 24,
        marginBottom: 2,
        marginTop: 5,
        color: '#333',
    },
    contact: {
        fontSize: 12,
        color: '#333',
    },
    ref_contact: {
        fontSize: 12,
        color: '#333',
        paddingLeft : 10
    },
    subheader: {
        fontSize: 18,
        marginBottom: 5,
        color: '#333',
    },
    title: {
        marginBottom: 3,
    },
    experiance: {
        fontSize: 12,
        marginBottom: 10,
        color: '#333',
        lineHeight: 1.3,
    },
    text: {
        fontSize: 12,
        marginBottom: 5,
        color: '#333',
        lineHeight: 1.3,

    },
    description: {
        fontSize: 11,
        marginBottom: 5,
        color: '#333',
        lineHeight: 1.3,
    },
    bold: {

        fontFamily: 'Roboto',
        fontWeight: 900

    },
    image: {
        width: 100,
        height: 100,
        marginBottom: 10,
        borderRadius: 50,
        margin: '0 auto',
        border: '2px solid #07142b'
    },
});

const Template_1 = ({ personalInfo, workHistory, skills, languageSkills, education, references }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section_header}>
                    <Image src={serverURL.RESUME_IMG_URL + personalInfo.img_path} style={styles.image} />
                    <Text style={styles.contact_name}>{personalInfo.first_name + ' ' + personalInfo.last_name}</Text>
                    <Text style={styles.contact}>Email: {personalInfo.email}</Text>
                    <Text style={styles.contact}>Phone: {personalInfo.phone}</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.title}>Summary</Text>
                    <Text style={styles.text}>{personalInfo.bio}</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.title}>Experience</Text>
                    {workHistory.map((wh, index) => (
                        <Text style={styles.experiance} key={index}>
                            <Text><Text style={styles.bold}>{wh.job_title}</Text> at {wh.employer} Company ({wh.start_month} {wh.start_year} - {wh.end_month} {wh.end_year}), {wh.location}</Text>{"\n"}
                            <Text>Descriptions:</Text>{"\n"}
                            <Text style={styles.description}>{wh.job_details}</Text>
                        </Text>
                    ))}
                </View>
                <View style={styles.section}>
                    <Text style={styles.title}>Skills</Text>
                    {skills.map((value, index) => (
                        <Text key={index} style={styles.text}>{value}</Text>
                    ))}
                </View>
                <View style={styles.section}>
                    <Text style={styles.title}>Languages</Text>
                    {languageSkills.map((value, index) => (
                        <View key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                            <Text style={styles.text}>{value.language_name} &nbsp;</Text>
                            <View style={{ display: 'flex', flexDirection: 'row' }}>
                                {Array.from({ length: value.rate }, (_, i) => (
                                    <Svg key={i} width={15} height={15}>
                                        <Circle cx={7.5} cy={7.5} r={5} fill="black" />
                                    </Svg>
                                ))}
                            </View>
                        </View>
                    ))}
                </View>

            </Page>
            <Page size="A4" style={styles.page}>

                <View style={styles.section}>
                    <Text style={styles.subheader}>Education</Text>
                    {education.map((value, index) => (
                        <Text key={index} style={styles.text}>- {value.degree} Degree in {value.field_of_study} - {value.school_name} ({value.grd_month} - {value.grd_year})</Text>
                    ))}
                </View>

                <View style={styles.section}>
                    <Text style={styles.subheader}>References</Text>

                    {references.map((value, index) => (
                        <>
                            <Text style={[styles.text, styles.bold]}>- {value.first_name + ' ' + value.last_name} : {value.position}</Text>
                            <Text style={styles.ref_contact}>Mobile #: {value.phone}</Text>
                            <Text style={styles.ref_contact}>Email: {value.email}</Text>
                        </>
                    ))}
                </View>
            </Page>
        </Document>
    );
}

export default Template_1;