import React, { useContext } from 'react';
import './Template.css';

import { Link } from 'react-router-dom';
import { ChevronRight } from "react-bootstrap-icons";

import { ResProfileContext } from '../../context/restaurant/ProfileContext';


import imgDemo1 from '../../assets-website/img/demos/demo-1.png';
import imgDemo2 from '../../assets-website/img/demos/demo-2.png';
import imgDemo3 from '../../assets-website/img/demos/demo-3.png';
import iconF1 from '../../assets-website/img/icons/f1.png';
import iconF2 from '../../assets-website/img/icons/f2.png';
import iconF3 from '../../assets-website/img/icons/f3.png';
import iconF4 from '../../assets-website/img/icons/f4.png';
import iconF5 from '../../assets-website/img/icons/f5.png';
import iconF6 from '../../assets-website/img/icons/f6.png';

const About = () => {

    // import profile context
    const { restaurantProfile } = useContext(ResProfileContext);

    return (
        <>
            {/* ##### Welcome Area Start #####  */}
            <div className="breadcumb-area clearfix dzsparallaxer auto-init" data-options='{direction: "normal"}'>
                <div className="divimage dzsparallaxer--target" style={{ width: '101%', height: '130%', backgroundImage: 'img/bg-img/bg-2.jpg' }}></div>
                {/* breadcumb content  */}
                <div className="breadcumb-content">
                    <div className="container h-100">
                        <div className="row h-100 align-items-center">
                            <div className="col-12">
                                <nav aria-label="breadcrumb" className="breadcumb--con text-center">
                                    <h2 className="w-text title wow fadeInUp text-dark" data-wow-delay="0.2s" >Our Templates</h2>
                                    <ol className="breadcrumb justify-content-center wow fadeInUp" data-wow-delay="0.4s">
                                        <li className="breadcrumb-item "><a href="#" className='text-dark'>Home</a></li>
                                        <li className="breadcrumb-item active text-dark" aria-current="page">OUR TEMPLATES</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ##### Welcome Area End #####   */}

            <section className="demo section-padding-100-0">
                <div className="container">
                    <div className="section-heading text-center">
                        <div className="dream-dots justify-content-center">
                            <span></span><span></span><span></span><span></span><span></span><span></span><span></span>
                        </div>
                        <h2 className="bold">Our Creative Templates</h2>
                        <p className='text-secondary fw-bold'>Easily design your resume in a unique and creative way using our templates.</p>
                    </div>
                    <div className="row">

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="demo-item">
                                <a href="template-preview.html"><img src={imgDemo1} alt="demo"
                                    className="img-responsive" /></a>
                                <div className="preview-btn-wrapper text-center">
                                    <a href="template-preview.html" className="preview-demo">See template <i
                                        className="fa fa-long-arrow-right"></i></a>
                                    <a href="template-edit.html" className="preview-demo v2">Use template <i
                                        className="fa fa-long-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="demo-item">
                                <a href="template-preview.html"><img src={imgDemo2} alt="demo"
                                    className="img-responsive" /></a>
                                <div className="preview-btn-wrapper text-center">
                                    <a href="template-preview.html" className="preview-demo">See template <i
                                        className="fa fa-long-arrow-right"></i></a>
                                    <a href="template-edit.html" className="preview-demo v2">Use template <i
                                        className="fa fa-long-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="demo-item">
                                <a href="template-preview.html"><img src={imgDemo3} alt="demo"
                                    className="img-responsive" /></a>
                                <div className="preview-btn-wrapper text-center">
                                    <a href="template-preview.html" className="preview-demo">See template <i
                                        className="fa fa-long-arrow-right"></i></a>
                                    <a href="template-edit.html" className="preview-demo v2">Use template <i
                                        className="fa fa-long-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>

             {/* Our features Area Start  */}
             <section className="our_services_area section-padding-100-70" id="services">
                <div className="container">

                    <div className="section-heading text-center">
                        {/* <!-- Dream Dots --> */}
                        <div className="dream-dots justify-content-center wow fadeInUp" data-wow-delay="0.2s">
                            <span></span><span></span><span></span><span></span><span></span><span></span><span></span>
                        </div>
                        <h2 className="wow fadeInUp" data-wow-delay="0.3s">Our Main Features</h2>
                        <p className="wow fadeInUp text-secondary fw-bold" data-wow-delay="0.4s">Explore our website for creating CVs and discover our main features.</p>
                    </div>


                    <div className="row">
                        <div className="col-12 col-sm-6 col-lg-4">
                            {/* <!-- Content --> */}
                            <div className="service_single_content text-center mb-100 wow fadeInUp" data-wow-delay="0.2s">
                                {/* <!-- Icon --> */}
                                <div className="service_icon">
                                    <img src={iconF1} alt="" />
                                </div>
                                <h6>Proven CV Templates to increase Hiring Chance</h6>
                                <p className='text-secondary'>Lorem ipsum dolor sit amet, consecte adipiscing elit. Nulla neque quam, maxim us ut accumsan
                                    ut, posuere sit Lorem ipsum adipiscing elit.</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                            {/* <!-- Content --> */}
                            <div className="service_single_content text-center mb-100 wow wow fadeInUp" data-wow-delay="0.3s">
                                {/* <!-- Icon --> */}
                                <div className="service_icon">
                                    <img src={iconF2} alt="" />
                                </div>
                                <h6>Creative, Modern and Clean Templates Design</h6>
                                <p className='text-secondary'>Lorem ipsum dolor sit amet, consecte adipiscing elit. Nulla neque quam, maxim us ut accumsan
                                    ut, posuere sit Lorem ipsum adipiscing elit.</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                            {/* <!-- Content --> */}
                            <div className="service_single_content text-center mb-100 wow fadeInUp" data-wow-delay="0.4s">
                                {/* <!-- Icon --> */}
                                <div className="service_icon">
                                    <img src={iconF3} alt="" />
                                </div>
                                <h6>Easy and Intuitive Online CV and Resume Builder </h6>
                                <p className='text-secondary'>Lorem ipsum dolor sit amet, consecte adipiscing elit. Nulla neque quam, maxim us ut accumsan
                                    ut, posuere sit Lorem ipsum adipiscing elit.</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                            {/* <!-- Content --> */}
                            <div className="service_single_content text-center mb-100 wow fadeInUp" data-wow-delay="0.5s">
                                {/* <!-- Icon --> */}
                                <div className="service_icon">
                                    <img src={iconF4} alt="" />
                                </div>
                                <h6>Free to use. Developed by hiring professionals.</h6>
                                <p className='text-secondary'>Lorem ipsum dolor sit amet, consecte adipiscing elit. Nulla neque quam, maxim us ut accumsan
                                    ut, posuere sit Lorem ipsum adipiscing elit.</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                            {/* <!-- Content --> */}
                            <div className="service_single_content text-center mb-100 wow fadeInUp" data-wow-delay="0.6s">
                                {/* <!-- Icon --> */}
                                <div className="service_icon">
                                    <img src={iconF5} alt="" />
                                </div>
                                <h6>Recruiter Approved Phrases with Module Notification</h6>
                                <p className='text-secondary'>Lorem ipsum dolor sit amet, consecte adipiscing elit. Nulla neque quam, maxim us ut accumsan
                                    ut, posuere sit Lorem ipsum adipiscing elit.</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                            {/* <!-- Content --> */}
                            <div className="service_single_content text-center mb-100 wow fadeInUp" data-wow-delay="0.7s">
                                {/* <!-- Icon --> */}
                                <div className="service_icon">
                                    <img src={iconF6} alt="" />
                                </div>
                                <h6>Fast Easy CV and Resume Formatting</h6>
                                <p className='text-secondary'>Lorem ipsum dolor sit amet, consecte adipiscing elit. Nulla neque quam, maxim us ut accumsan
                                    ut, posuere sit Lorem ipsum adipiscing elit.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Our features Area End */}

        </>
    )
}

export default About