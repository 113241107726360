import React, { useContext, useState, useEffect, url } from 'react';
import axios from 'axios';
import './Finalize.css';

import { Link, useNavigate } from 'react-router-dom';
import { Border, ChevronRight, PlugFill, Plus, Trash } from "react-bootstrap-icons";

// templates images
import temp from '../../../assets/img/templates/temp-1.jpg'
import temp1 from '../../../assets/img/templates/temp-1.png'
import temp2 from '../../../assets/img/templates/temp-2.png'

// components
import * as serverURL from '../../../config/Constants'

// providers
import { useResumeStep } from '../../../context/resume-step/ResumeStepContext';
import { ValidationContext } from '../../../context/validation/ValidationContext';
import { useACVResume } from '../../../context/resume/ResumeContext';

// MUI library
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

const Language = () => {
    const colors = ['#7d7d7d', '#0d709a', '#1c9ebc', '#d71c4c', '#f69227', '#f7ce2b'];

    const navigate = useNavigate();
    const { setResumeStep } = useResumeStep();
    const { acvResume, setAcvResume } = useACVResume();
    const [dailogOpen, setDailogOpen] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [error, setError] = useState({});
    const [templateColor, setTemplateColor] = useState(colors[1]);
    const [templateId, setTemplateId] = useState(1);

    // import validtaion context params
    const { validate, setFormErrorEmpty, formError, setFormError } = useContext(ValidationContext);




    // close the confirmation dialog 
    const handleDailogClose = () => {
        setDailogOpen(false);
    };
    const handleTemplate = (e, id) => {
        e.preventDefault()
        setTemplateId(id);
    };

    const nextFinish = () => {
        setDailogOpen(false);
        setBackdropOpen(true);
        setTimeout(() => {
            navigate('/resume/download-resume', {stats : {temp_id : templateId, temp_color : templateColor}});
        }, 5000);
        
    };

    return (
        <>
            <div className="main-content float-end col-lg-10 col-12">
                <div className="title text-center">
                    Choose from our best templates for your resume
                </div>
                <div className="sub-title text-center">
                    choose a color for template 
                </div>
                <div className="color-picker text-center">
                    <label htmlFor="" style={{ marginLeft: '-50px' }}>
                        <b>COLOR </b>
                    </label>
                    {colors.map((color, index) => (
                        <label className="color-option"  key={index} onClick={() => setTemplateColor(color)}>
                            <input className="color-radio"  type="radio" name="radio" value={color} />
                            <span className="checkmark" style={{ backgroundColor: color }}></span>
                        </label>
                    ))}
                </div>
                <br />
                <div className="hr-container">
                    <hr className="hr-line" />
                    <span className="hr-text">Templates</span>
                    <hr className="hr-line" />
                </div>
                <Container className='mt-2' fixed>
                    <div className="col-12 row">
                        <div className="col-3">
                            <a href="#" className="card card-clickable" onClick={(e) => handleTemplate(e, 1)} style={{ margin: '0' }}>
                                <div className="card-body" style={{ padding: '5px' }}>
                                    <img src={temp} alt="" style={{ borderRadius: '10px' }} />
                                </div>
                            </a>
                        </div>
                        <div className="col-3">
                            <a className="card card-clickable" onClick={(e) => handleTemplate(e, 1)} style={{ margin: '0' }}>
                                <div className="card-body" style={{ padding: '5px' }}>
                                    <img src={temp} alt="" style={{ borderRadius: '10px' }} />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-12 mt-3 text-end">
                        <button className='btn btn-dark btn-circle px-5' style={{paddingTop : '10px',paddingBottom : '10px'}} onClick={nextFinish}> Finish Resume</button>
                    </div>
                </Container>
            </div>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default Language