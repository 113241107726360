import React, { useState, useEffect, useContext } from 'react';
import './style.css'
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import { ResProfileContext } from '../../context/restaurant/ProfileContext';
import { useAuth } from '../../context/auth/AuthContext';
import { slide as Menu } from 'react-burger-menu';

import logo from '../../assets-website/img/core-img/logo.png';
import Logo from '../../assets/img/logo/favicon.png';

// miu library
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

const Header = () => {

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);


    // get the route name
    const location = useLocation();
    const currentRoute = location.pathname.split('/')[1];
    // end of route name

    const { user } = useAuth();

    const { id } = useParams();


    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const DrawerList = (
        <Box sx={{ width: 250, paddingTop: 2 }} role="presentation" onClick={toggleDrawer(false)}>
            <List>
                <ListItem disablePadding>
                    <ListItemButton>
                        <Link to={'/'}><ListItemText primary={'Home'} /></Link>
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton>
                        <Link to={'/about'}><ListItemText primary={'About us'} /></Link>
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton>
                        <Link to={'/templates'}><ListItemText primary={'Templates'} /></Link>
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton>
                        <Link to={'/contact'}><ListItemText primary={'Contact us'} /></Link>
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
        </Box>
    );

    return (
        <>
            {/* Preloader  */}
            {/* <div id="preloader">
                <div className="preload-content">
                    <div id="dream-load"></div>
                </div>
            </div> */}

            <Drawer open={open} onClose={toggleDrawer(false)} sx={{ zIndex: 10000 }}>
                {DrawerList}
            </Drawer>


            {/* <!-- ##### Header Area Start ##### --> */}
            <header className="header-area" >
                <div className="classy-nav-container dark breakpoint-off">
                    <div className="container">
                        {/* <!-- classNamey Menu --> */}
                        <nav className="classy-navbar justify-content-between" id="dreamNav">

                            {/* <!-- Logo --> */}
                            <a className="nav-brand" href="index-2.html"><img src={Logo} alt="logo" /> ALIEN CV.</a>

                            {/* <!-- Navbar Toggler --> */}
                            <div className="classy-navbar-toggler">
                                <span className="navbarToggler" onClick={toggleDrawer(true)}><span></span><span></span><span></span></span>
                            </div>

                            {/* <!-- Menu --> */}
                            <div className="classy-menu">

                                {/* <!-- close btn --> */}
                                <div className="classycloseIcon">
                                    <div className="cross-wrap"><span className="top"></span><span className="bottom"></span></div>
                                </div>

                                {/* <!-- Nav Start --> */}
                                <div className="classynav">
                                    <ul id="nav">
                                        <li><Link to={'/'}>Home</Link></li>
                                        <li><Link to={'/about'}>About Us</Link></li>
                                        {/* <li><a href="pricing.html">Pricing</a></li> */}
                                        <li><Link to={'/templates'}>Templates</Link></li>
                                        <li><Link to={'/contact'}>Contact</Link></li>
                                    </ul>

                                    {/* <!-- Button --> */}
                                    {/* <a href="#test-popup" className="open-popup-link btn login-btn mr-im">Log in</a>
                                    <a href="#signup-popup" className="open-signup-link btn login-btn">Signup </a> */}
                                </div>
                                {/* <!-- Nav End --> */}
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
            {/* <!-- ##### Header Area End ##### --> */}


        </>
    )
}

export default Header