import React, { useState, useContext } from 'react'
import './User.css'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

// import provides
import { useAuth } from '../../../context/auth/AuthContext';
import { ResProfileContext } from '../../../context/restaurant/ProfileContext';

// components
import * as serverURL from '../../../config/Constants'

// MUI Library
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';

import { styled } from '@mui/material/styles';
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
const User = () => {
    const { user, setUser } = useAuth();
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);
    const [snakbarOpen, setSnakbarOpen] = useState(false);
    const [profileImage, setProfileImage] = useState( user.img_path);
    const navigate = useNavigate();

    // import profile context
    const { restaurantProfile } = useContext(ResProfileContext);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let fd = new FormData()
        fd.append("email", user.email);
        fd.append("full_name", data.get('fullname'));
        fd.append("img_path", data.get('profile_pic'));

        setLoading(true)
        try {
            axios.post(serverURL.SERVER_URL + 'user/edit', fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'token': user.token
                }
            }).then((result) => {
                const data = result.data;
                if (result.status === 200) {
                    setUser(data.user);
                    setSnakbarOpen(true)
                }
                setLoading(false)
            }).catch(function (error) {
                setError(error.response.data.message)
                setLoading(false)
            })
        } catch (error) {
        }

    };

    // snakbar handlers
    const handleSnackbarClose = () => {
        setSnakbarOpen(false)
    }

    // priview profile pic
    const handleChangeImage = (e) => {
        setProfileImage({ [e.target.name]: URL.createObjectURL(e.target.files[0]) }.profile_pic)
    }
    return (
        <>
            <Box sx={{ width: '100%', bgcolor: 'background.paper' }} component="form" encType="multipart/form-data" className={`p-2`} onSubmit={handleSubmit} noValidate>
                <Card sx={{ minWidth: 275, maxHeight: 150 }} className='text-center' >
                    <CardContent sx={{ padding: 0 }}>
                        <Grid container>
                            <Grid item xs={12} sm={12} sx={{ marginTop: 2 }}>
                                <Avatar style={{ margin: "0 auto" }} alt={user.full_name} src={profileImage} sx={{ width: 90, height: 90 }} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Button component="label" className='textColor' style={{ margin: "0 auto" }}>
                                    Edits
                                    <VisuallyHiddenInput type="file" name="profile_pic" onChange={handleChangeImage} />
                                </Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                <Grid container spacing={2} sx={{ marginTop: 2 }}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            autoComplete="given-name"
                            name="fullname"
                            required
                            fullWidth
                            id="fullname"
                            label="Full Name"
                            defaultValue={user.full_name}
                        // error={formError.type == 'FullName' ? true : false}
                        // helperText={formError.type == 'FullName' ? formError.error : ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            disabled
                            fullWidth
                            label="Email"
                            defaultValue={user.email}
                        // error={formError.type == 'Email' ? true : false}
                        // helperText={formError.type == 'Email' ? formError.error : ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            className={'bg-app-color'}
                            startIcon={loading && <CircularProgress size={20} sx={{ color: 'grey.500' }} />}
                            disabled={loading}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Snackbar
                open={snakbarOpen}
                autoHideDuration={1500}
                message="Changes saved"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                sx={{ marginBottom: 7 }}
                onClose={handleSnackbarClose}
            />
        </>
    )
}

export default User