import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import './WorkHistory.css';

import { Link, useNavigate } from 'react-router-dom';
import { Border, ChevronRight, Plus, Trash } from "react-bootstrap-icons";

// components
import * as serverURL from '../../../config/Constants'

// images
import noImage from '../../../assets/img/rect-female-no-image.jpg'

// providers
import { useResumeStep } from '../../../context/resume-step/ResumeStepContext';
import { ValidationContext } from '../../../context/validation/ValidationContext';
import { useACVResume } from '../../../context/resume/ResumeContext';

// MUI library
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Tooltip from '@mui/material/Tooltip';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const WorkHistory = () => {

    // import validtaion context params
    const { validate, setFormErrorEmpty, formError, setFormError } = useContext(ValidationContext);
    const { acvResume, setAcvResume } = useACVResume();

    // properties
    const navigate = useNavigate();
    const { setResumeStep } = useResumeStep();
    const [dailogOpen, setDailogOpen] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [error, setError] = useState({});
    const [jobs, setJobs] = useState([]);
    const [jobId, setJobId] = useState(1);
    const years = [];

    const currentYear = moment().year();
    for (let year = currentYear; year >= 1950; year--) {
        years.push(<option value={year} key={year}>{year}</option>);
    }
    // close the confirmation dialog 
    const handleDailogClose = () => {
        setDailogOpen(false);
    };

    useEffect(() => {
        if (acvResume) {
            axios.get(serverURL.SERVER_URL + 'work-history/' + acvResume.resume_id, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': '$2y$10$e6tbgQSbvmC6X7us57XsIeuBZT7eZYqiu/zDTKjKNX4KL9wQEOvEG'
                }
            }).then((result) => {
                const data = result.data;
                setJobs(data.work_histories);
            })
        }
    }, [])


    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if (validate('input', 'Job title', data.get('job-title')) == -1) {
            return
        } if (validate('input', 'Employer', data.get('employer')) == -1) {
            return
        }
        if (validate('input', 'Location', data.get('location')) == -1) {
            return
        }

        const job = {
            id: jobId,
            job_title: data.get('job-title'),
            employer: data.get('employer'),
            location: data.get('location'),
            start_month: data.get('start-month') == 'Month' ? '' : data.get('start-month'),
            start_year: data.get('start-year') == 'Year' ? '' : data.get('start-year'),
            end_month: data.get('end-month') == 'Month' ? '' : data.get('end-month'),
            end_year: data.get('end-year') == 'Year' ? '' : data.get('end-year'),
            job_details: data.get('job-details')
        };
        setJobs([...jobs, job]);
        setJobId(jobId + 1);
        const form = event.currentTarget;
        const formInputs = form.querySelectorAll('input, select, textarea');
        formInputs.forEach(input => input.value = '');
    }

    const handleDeleteJob = (id) => {
        const updatedJobs = jobs.filter(job => job.id !== id);
        setJobs(updatedJobs);
    };
    const cehckWorkHistory = () => {
        if (jobs.length < 1) {
            setDailogOpen(true);
        } else {
            nextEducation();
        }
    };
    const nextEducation = () => {
        setDailogOpen(false);
        setBackdropOpen(true);
        try {
            axios.post(serverURL.SERVER_URL + 'work-history/' + acvResume.resume_id, { jobs: jobs }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then((result) => {
                const data = result.data;
                if (result.status === 200) {
                    setResumeStep(3);
                    navigate('/resume/education')
                }
            }).catch(function (error) {
                setBackdropOpen(false);
                setError(error.message)
            })
        } catch (error) {
            setBackdropOpen(false);
        }
    }

    return (
        <>
            <div className="main-content float-end col-lg-10 col-12">
                <div className="title">
                    Tell us about your most recent job
                </div>
                <div className="sub-title">
                    We’ll start there and work backward.
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className=" mb-4">
                            <div className="card-body">
                                <form onSubmit={handleSubmit} method='post'>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="mb-4">
                                                <label htmlFor="job-title" className="form-label">Job Title</label>
                                                <input type="text" placeholder="Type here *" name={'job-title'} className={`form-control ${formError.type == 'Job title' && 'error'}`} id="job-title" />
                                                {
                                                    formError.type == 'Job title' && (
                                                        <label className="text-danger">{formError.error}</label>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-4">
                                                <label htmlFor="employer" className="form-label">Employer</label>
                                                <input type="text" placeholder="Type here *" name={'employer'} className={`form-control ${formError.type == 'Employer' && 'error'}`} id="employer" />
                                                {
                                                    formError.type == 'Employer' && (
                                                        <label className="text-danger">{formError.error}</label>
                                                    )
                                                }
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="mb-4">
                                                <label htmlFor="location" className="form-label">Location</label>
                                                <input type="text" name={'location'} placeholder="Type here *" className={`form-control ${formError.type == 'Location' && 'error'}`} id="location" />
                                                {
                                                    formError.type == 'Location' && (
                                                        <label className="text-danger">{formError.error}</label>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6"></div>
                                        <div className="col-lg-3">
                                            <label className="form-label">Start Date</label>
                                            <select className="form-select" name={'start-month'} >
                                                <option> Month </option>
                                                <option value={'01'}> JANUARY </option>
                                                <option value={'02'}> FEBERWARY </option>
                                                <option value={'03'}> MARCH </option>
                                                <option value={'04'}> APRIL </option>
                                                <option value={'05'}> MAY </option>
                                                <option value={'06'}> JUNE </option>
                                                <option value={'07'}> JULY </option>
                                                <option value={'08'}> AGUSTE </option>
                                                <option value={'09'}> SEPTEMBER </option>
                                                <option value={'10'}> OCTOBAR </option>
                                                <option value={'11'}> NEVEMBER </option>
                                                <option value={'12'}> DECEMBER </option>
                                            </select>
                                        </div>
                                        <div className="mb-4 col-lg-3">
                                            <label className="form-label">&nbsp;</label>
                                            <select className="form-select" name={'start-year'}>
                                                <option> Year </option>
                                                {years}
                                            </select>
                                        </div>
                                        <div className="col-lg-3">
                                            <label className="form-label">End Date</label>
                                            <select className="form-select" name={'end-month'}>
                                                <option> Month </option>
                                                <option value={'01'}> JANUARY </option>
                                                <option value={'02'}> FEBERWARY </option>
                                                <option value={'03'}> MARCH </option>
                                                <option value={'04'}> APRIL </option>
                                                <option value={'05'}> MAY </option>
                                                <option value={'06'}> JUNE </option>
                                                <option value={'07'}> JULY </option>
                                                <option value={'08'}> AGUSTE </option>
                                                <option value={'09'}> SEPTEMBER </option>
                                                <option value={'10'}> OCTOBAR </option>
                                                <option value={'11'}> NEVEMBER </option>
                                                <option value={'12'}> DECEMBER </option>
                                            </select>
                                        </div>
                                        <div className="mb-4 col-lg-3">
                                            <label className="form-label">&nbsp;</label>
                                            <select className="form-select" name={'end-year'}>
                                                <option> Year </option>
                                                {years}
                                            </select>
                                        </div>
                                        <div className="mb-4 col-lg-12">
                                            <label className="form-label">Job Details</label>
                                            <textarea type="text" name={'job-details'} placeholder="Type here" className={`form-control`} id="description" cols="30" rows="0"></textarea>
                                        </div>

                                        <div className="col-12 text-end">
                                            <button className='btn btn-app-primary'>Add <Plus style={{ fontSize: '18px' }} /></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th scope="col">Job Title</th>
                                <th scope="col">Employar</th>
                                <th scope="col">Location</th>
                                <th scope="col">Start Date</th>
                                <th scope="col">End Date</th>
                                <th scope="col">Job Details</th>
                                <th scope="col"> Action </th>
                            </tr>
                        </thead>
                        <tbody>

                            {jobs.map((job, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{job.job_title}</td>
                                    <td>{job.employer}</td>
                                    <td>{job.location}</td>
                                    <td>{job.start_month} - {job.start_year}</td>
                                    <td>{job.end_month} - {job.end_year}</td>
                                    <td>
                                        <Tooltip title={job.job_details} placement="top">
                                            <span> {job.job_details ? job.job_details.substring(0, 20) + '..' : ''}</span>
                                        </Tooltip>
                                    </td>
                                    <td>
                                        <a className="" onClick={() => handleDeleteJob(job.id)}><Trash /></a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="col-12 text-end">
                    <Link to={'/resume/personal-info'} onClick={() => setResumeStep(1)} className='btn btn-outline-app-primary btn-circle me-1'> Previous</Link>
                    <button className='btn btn-dark btn-circle ' onClick={cehckWorkHistory}> Next : Education</button>
                </div>
            </div>

            {/* confirm place order dialog */}
            <Dialog
                open={dailogOpen}
                onClose={handleDailogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Warning, you didn't add your work histories!"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleDailogClose}>Add</Button>
                    <Button onClick={nextEducation}>
                        Containue
                    </Button>
                </DialogActions>
            </Dialog>
            {/* end confirm place order dialog */}

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default WorkHistory