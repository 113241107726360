import React, { useState, createContext } from 'react';

export const NotificationContext = createContext(null);

export const NotificationContextProvider = (props) => {

    const [notification, setNotification] = useState(0);

    
    const contextValue = { notification, setNotification};

    return <NotificationContext.Provider value={contextValue}>{props.children}</NotificationContext.Provider>
}



