import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import './Reference.css';

import { Link, useNavigate } from 'react-router-dom';
import { Border, ChevronRight, PlugFill, Plus, Trash } from "react-bootstrap-icons";

// components
import * as serverURL from '../../../config/Constants'

// providers
import { useResumeStep } from '../../../context/resume-step/ResumeStepContext';
import { ValidationContext } from '../../../context/validation/ValidationContext';
import { useACVResume } from '../../../context/resume/ResumeContext';

// MUI library
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';


const Reference = () => {

    // import validtaion context params
    const { validate, setFormErrorEmpty, formError, setFormError } = useContext(ValidationContext);
    const { acvResume, setAcvResume } = useACVResume();

    const navigate = useNavigate();
    const { setResumeStep } = useResumeStep();
    const [dailogOpen, setDailogOpen] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [error, setError] = useState({});
    const [references, setReferences] = useState([]);
    const [refId, setRefId] = useState(1);

    // close the confirmation dialog 
    const handleDailogClose = () => {
        setDailogOpen(false);
    };

    useEffect(() => {
        if (acvResume) {
            axios.get(serverURL.SERVER_URL + 'references/' + acvResume.resume_id, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': '$2y$10$e6tbgQSbvmC6X7us57XsIeuBZT7eZYqiu/zDTKjKNX4KL9wQEOvEG'
                }
            }).then((result) => {
                const data = result.data;
                setReferences(data.references);
            })
        }
    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if (validate('input', 'First name', data.get('first_name')) == -1) {
            return
        }
        if (validate('input', 'Last name', data.get('last_name')) == -1) {
            return
        }
        if (validate('input', 'Position', data.get('position')) == -1) {
            return
        }
        if (validate('email', 'Email', data.get('email')) == -1) {
            return
        }

        const reference = {
            id: refId,
            first_name: data.get('first_name'),
            last_name: data.get('last_name'),
            position: data.get('position'),
            phone: data.get('phone'),
            email: data.get('email')
        };
        setReferences([...references, reference]);
        setRefId(refId + 1);

        const form = event.currentTarget;
        const formInputs = form.querySelectorAll('input');
        formInputs.forEach(input => input.value = '');

    };

    const handleDeleteReference = (id) => {
        const updatedReferences = references.filter(reference => reference.id !== id);
        setReferences(updatedReferences);
    };

    const cehckReferences = () => {
        if (references.length < 1) {
            setDailogOpen(true);
        } else {
            nextSummary();
        }
    };

    const nextSummary = () => {
        setDailogOpen(false);
        setBackdropOpen(true);
        setFormErrorEmpty();
        try {
            axios.post(serverURL.SERVER_URL + 'references/' + acvResume.resume_id, { references: references }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then((result) => {
                const data = result.data;
                if (result.status === 200) {
                    setResumeStep(7);
                    navigate('/resume/summary')
                }
            }).catch(function (error) {
                setBackdropOpen(false);
                setError(error.message)
            })
        } catch (error) {
            setBackdropOpen(false);
        }
    }

    return (
        <>
            <div className="main-content float-end col-lg-10 col-12">
                <div className="title">
                    Tell us about your references
                </div>
                <div className="sub-title">
                    Write down your references.
                </div>

                <Container className='mt-3' fixed>
                    <Box component="form" onSubmit={handleSubmit} noValidate id='feedback-form'>
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div className=" mb-4">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="mb-4">
                                                    <label htmlFor="first_name" className="form-label">First Name *</label>
                                                    <input type="text" name='first_name' placeholder="Type here" className={`form-control ${formError.type == 'First name' && 'error'}`} id="first_name" />
                                                    {
                                                        formError.type == 'First name' && (
                                                            <label className="text-danger">{formError.error}</label>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-4">
                                                    <label htmlFor="last_name" className="form-label">Last Name *</label>
                                                    <input type="text" name='last_name' placeholder="Type here " className={`form-control ${formError.type == 'Last name' && 'error'}`} id="last_name" />
                                                    {
                                                        formError.type == 'Last name' && (
                                                            <label className="text-danger">{formError.error}</label>
                                                        )
                                                    }
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="mb-4">
                                                    <label htmlFor="position" className="form-label">Position *</label>
                                                    <input type="text" name='position' placeholder="Type here" className={`form-control ${formError.type == 'Position' && 'error'}`} id="position" />
                                                    {
                                                        formError.type == 'Position' && (
                                                            <label className="text-danger">{formError.error}</label>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="mb-4">
                                                    <label htmlFor="phone" className="form-label">Phone</label>
                                                    <input type="text" name='phone' placeholder="Type here" className="form-control" id="phone" />
                                                </div>
                                            </div>
                                            <div className="mb-4 col-lg-3">
                                                <div className="mb-4">
                                                    <label htmlFor="email" className="form-label">Email *</label>
                                                    <input type="text" name='email' placeholder="Type here" className={`form-control ${formError.type == 'Email' && 'error'}`} id="email" />
                                                    {
                                                        formError.type == 'Email' && (
                                                            <label className="text-danger">{formError.error}</label>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-12 text-end">
                                                <button className='btn btn-app-primary'>Add <Plus style={{ fontSize: '18px' }} /></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>

                    <div className="container mt-4 table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th scope="col">Full Name</th>
                                    <th scope="col">Position</th>
                                    <th scope="col">Phone</th>
                                    <th scope="col">Email</th>
                                    <th scope="col"> Action </th>
                                </tr>
                            </thead>
                            <tbody>
                                {references.map((ref, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{ref.first_name} {ref.last_name}</td>
                                        <td>{ref.position}</td>
                                        <td>{ref.phone}</td>
                                        <td>{ref.email}</td>
                                        <td>
                                            <a className="" onClick={() => handleDeleteReference(ref.id)}><Trash /></a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-12 text-end mt-3">
                        <Link to={'/resume/language'} onClick={() => setResumeStep(5)} className='btn btn-outline-app-primary btn-circle me-1'> Previous</Link>
                        <button className='btn btn-dark btn-circle' onClick={cehckReferences}> Next : Summary</button>
                    </div>
                </Container>
            </div>

            {/* confirm place order dialog */}
            <Dialog
                open={dailogOpen}
                onClose={handleDailogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Warning, you didn't add your work histories!"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleDailogClose}>Add</Button>
                    <Button onClick={nextSummary}>
                        Containue
                    </Button>
                </DialogActions>
            </Dialog>
            {/* end confirm place order dialog */}

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default Reference