import { createBrowserRouter } from 'react-router-dom';
import Layout from '.././components/layout/Layout'
import LayoutSecondary from '.././components/layout-secondary/Layout'
import LayoutWebsite from '.././components/layout-website/Layout'
import Home from '.././pages/Home'
import Contact from '../pages/contact/Contact'
import Template from '../pages/template/Template'
import About from '../pages/about/About'

import NotFound from '.././pages/NotFound'

import Profile from '.././pages/profile/Profile'
import Privacy from '.././pages/profile/privacy/Privacy'
import UserProfile from '.././pages/profile/user/User'


// cv builder pages
import GetStart from '.././pages/build-cv/get-start/GetStart'
import PersonalInfo from '.././pages/build-cv/personal-info/PersonalInfo'
import WorkHistory from '.././pages/build-cv/work-history/WorkHistory'
import Education from '.././pages/build-cv/education/Education'
import Skill from '.././pages/build-cv/skill/Skill'
import Language from '.././pages/build-cv/language/Language'
import Reference from '.././pages/build-cv/reference/Reference'
import Summary from '.././pages/build-cv/summary/Summary'
import Finalize from '.././pages/build-cv/finalize/Finalize'
import DownloadResume from '.././pages/build-cv/download-resume/DownloadResume'


// Auth pages
import SignUp from '.././pages/auth/signup/SignUp'
import SignIn from '.././pages/auth/signin/SignIn'
import Verify from '.././pages/auth/verify/Verify'

// providers
import { useAuth } from '../context/auth/AuthContext';

const createRoutes = () => {
	// const { user } = useAuthInRoutes();

	const routes = [
		{
			path: '/',
			element: <LayoutWebsite />,
			children: [
				{
					path: '/',
					element: <Home />,
				},
				{
					path: '/about',
					element: <About />,
				},
				{
					path: '/contact',
					element: <Contact />,
				},
				{
					path: '/templates',
					element: <Template />,
				},
			],
		},
		{
			path: '/',
			element: <LayoutSecondary />,
			children: [
				{
					path: '/get-start',
					element: <GetStart />,
				},
			],
		},
		{
			path: '/',
			element: <Layout />,
			children: [

				{
					path: '/resume/personal-info',
					element: <PersonalInfo />,
				},
				{
					path: '/resume/work-history',
					element: <WorkHistory />,
				},
				{
					path: '/resume/education',
					element: <Education />,
				},
				{
					path: '/resume/skills',
					element: <Skill />,
				},
				{
					path: '/resume/language',
					element: <Language />,
				},
				{
					path: '/resume/references',
					element: <Reference />,
				},
				{
					path: '/resume/summary',
					element: <Summary />,
				},
				{
					path: '/resume/finalize',
					element: <Finalize />,
				},
				{
					path: '/resume/download-resume',
					element: <DownloadResume />,
				},
				
				{
					path: '/signin',
					element: <SignIn />,
				},
				{
					path: '/signup',
					element: <SignUp />,
				},
				{
					path: '/email/verify',
					element: <Verify />,
				},
			],
		},
		{
			path: '/',
			children: [

				{
					path: '/*',
					element: <NotFound />,
				},

			],
		},
	];

	if (localStorage.getItem('royabranduser') != null) {
		routes[0].children.push(
			{
				path: '/profile',
				element: <Profile />,
			},
		);
	}

	return routes;
};

export default createBrowserRouter(createRoutes());

