import React, { useContext, useRef, useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './Verify.css'

// all server APIs url
import * as serverURL from '../../../config/Constants'

//MUI LIbrary
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';

export default function SignUp() {
  const [error, setError] = React.useState({});
  const [verificationEmailSent, setVerificationEmailSent] = React.useState(false);
  const [verificationEmailSentAlert, setVerificationEmailSentAlert] = React.useState(false);

  const state = useLocation().state;
  const useremail = localStorage.getItem('rbranduseremail');

  const navigate = useNavigate();
  useEffect(function () {
    if (useremail == null) {
      navigate('/')
    }
  })

  // snakbar handlers
  const handleSnackbarClose = () => {
    setVerificationEmailSentAlert(false)
  }

  const sendVerificationEmail = () => {
    setVerificationEmailSent(true);
    try {
      axios.post(serverURL.SERVER_URL + 'auth/send-verify', { email: useremail }, {
        headers: {
          'Content-Type': 'application/json',
        }
      }).then((result) => {
        setVerificationEmailSent(false);
        setVerificationEmailSentAlert(true);

        const data = result.data;
        console.log(data);
        if (data.status == true) {

        }
      }).catch(function (error) {
        setError(error)
      })
    } catch (error) {
    }
  }
  return (
    <>
      <br /><br />
      <div className="container contact_container" >
        <div className="col-lg-5 m-auto mt-5">
          <div className="row">
            <div className="col-lg-12">
              <div className="login_wrap widget-taber-content p-30 background-white border-radius-5">
                <div className="padding_eight_all bg-white">
                  <div className="heading_s1">
                    <h4 className="mb-30">VERIFY YOUR EMAIL ADDRESS</h4>
                    <h4 className="mb-30"></h4>
                  </div>
                  <p className="mb-50 font-sm">
                    Before proceeding, please check your email for a verification link.
                  </p>
                  <p className="mb-50 font-sm">
                    If you did not receive the email,
                  </p>
                  <div className=" mt-30">
                    {verificationEmailSent ? (
                      <CircularProgress size={20} />
                    ) : (
                      <Link onClick={sendVerificationEmail} className={'text-primary mr-1'}>
                        click here to request another
                      </Link>
                    )}

                    /
                    <Link to="/" className={'text-primary ml-1'}>
                      sign out
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Snackbar
        open={verificationEmailSentAlert}
        autoHideDuration={2500}
        message="a verification email has been sent to your email"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleSnackbarClose}
      />
    </>
  );
}