import { createContext, useContext, useState } from 'react';
import moment from 'moment';
import { create } from '@mui/material/styles/createTransitions';
import { jsx } from '@emotion/react';

const AVCResumeContent = createContext({
    acvResume: null,
    setAcvResume: () => { },
    isAcvResume: () => { },
});

export const ACVResumeProvider = ({ children }) => {
    const [acvResume, _setAcvResume] = useState(
        JSON.parse(localStorage.getItem('acv_resume')) || null
    );

    // set user to local storage
    const setAcvResume = (id) => {
        const resume = {
            resume_id: id,
            created_at: moment.now()
        }
        localStorage.setItem('acv_resume', JSON.stringify(resume));
        _setAcvResume(resume);
    };

    return (
        <AVCResumeContent.Provider value={{ acvResume, setAcvResume }}>
            {children}
        </AVCResumeContent.Provider>
    );
};

export const useACVResume = () => {
    return useContext(AVCResumeContent);
};
