import React, { useContext, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import './DownloadResume.css';

import img from '../../../assets/img/rect-female-no-image.jpg'
import { Link, useNavigate } from 'react-router-dom';
import { Envelope, TelephoneFill, Globe, Dot } from "react-bootstrap-icons";

// components
import * as serverURL from '../../../config/Constants'

// providers
import { useResumeStep } from '../../../context/resume-step/ResumeStepContext';
import { ValidationContext } from '../../../context/validation/ValidationContext';
import { useACVResume } from '../../../context/resume/ResumeContext';


// templates
import Template_1 from '../../../components/resume-templates/template-1/Template_1'
import InvoiceDocument from '../../../components/resume-templates/template-1/InvoiceDocument'
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';

// MUI library
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import Backdrop from '@mui/material/Backdrop';

const Language = () => {

    const navigate = useNavigate();
    const { setResumeStep } = useResumeStep();
    const { acvResume, setAcvResume } = useACVResume();
    const [dailogOpen, setDailogOpen] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [error, setError] = useState({});
  
    const [personalInfo, setPersonalInfo] = useState({});
    const [workHistory, setWrokHistory] = useState([]);
    const [education, setEducation] = useState([]);
    const [skills, setSkills] = useState(['']);
    const [languageSkills, setLanguageSkills] = useState([]);
    const [references, setReferences] = useState([]);

    // import validtaion context params
    const { validate, setFormErrorEmpty, formError, setFormError } = useContext(ValidationContext);

    useEffect(() => {
        if (acvResume) {
            axios.get(serverURL.SERVER_URL + 'resume-info/' + acvResume.resume_id, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': '$2y$10$e6tbgQSbvmC6X7us57XsIeuBZT7eZYqiu/zDTKjKNX4KL9wQEOvEG'
                }
            }).then((result) => {
                const data = result.data;
                setPersonalInfo(data.personal_info);
                setWrokHistory(data.workhistories);
                setEducation(data.educations);
                setSkills(data.skills);
                setLanguageSkills(data.language_skills);
                setReferences(data.references);

            })
        }
    }, [])

    // close the confirmation dialog 
    const handleDailogClose = () => {
        setDailogOpen(false);
    };

    const modifyResume = () => {
        setDailogOpen(false);
        setBackdropOpen(true);
        setResumeStep(1);
        setTimeout(() => {
            navigate('/resume/personal-info');
        }, 3000);
    };

    return (
        <>
            <div className="main-content float-end col-lg-10 col-12">
                <div className="title">
                    Congratulation, your resume is ready.
                </div>
                <div className="sub-title">
                    you can download or modify your resume.

                </div>


                <Container className='mt-2' fixed>
                    <div className="col-12 row">
                        <div className="col-lg-8 template-container d-none d-lg-block" id='template-container'>
                            <PDFViewer showToolbar={false} style={{ width: '100%', height: '100%' }}>
                                <Template_1 personalInfo={personalInfo} workHistory={workHistory} education={education} skills={skills} languageSkills={languageSkills} references={references} />
                            </PDFViewer>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <PDFDownloadLink
                                className='btn btn-dark btn-circle px-5 w-100 '
                                style={{ paddingTop: '10px', paddingBottom: '10px' }}
                                document={<Template_1 personalInfo={personalInfo} workHistory={workHistory} education={education} skills={skills} languageSkills={languageSkills} references={references} />}
                                fileName={personalInfo.first_name + ' ' + personalInfo.last_name + ' Resume.pdf'}
                            >
                                {({ blob, url, loading, error }) =>
                                    loading ? "Loading..." : "Download Resume"
                                }
                            </PDFDownloadLink>
                            <button className='btn btn-circle px-5 w-100 mt-3 btn-app-primary' style={{ paddingTop: '10px', paddingBottom: '10px' }} onClick={modifyResume}> Modify Resume</button>
                        </div>
                    </div>


                </Container>
            </div>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default Language