import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import './Education.css';

import { Link, useNavigate } from 'react-router-dom';
import { ChevronRight, Plus, Trash } from "react-bootstrap-icons";

// components
import * as serverURL from '../../../config/Constants'

// providers
import { useResumeStep } from '../../../context/resume-step/ResumeStepContext';
import { ValidationContext } from '../../../context/validation/ValidationContext';
import { useACVResume } from '../../../context/resume/ResumeContext';

// MUI library
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Rating from '@mui/material/Rating';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Backdrop from '@mui/material/Backdrop';

const Education = () => {
    // import validtaion context params
    const { validate, setFormErrorEmpty, formError, setFormError } = useContext(ValidationContext);
    const { acvResume, setAcvResume } = useACVResume();

    const navigate = useNavigate();
    const [dailogOpen, setDailogOpen] = useState(false);
    const [error, setError] = useState({});
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [profileImage, setProfileImage] = useState(null);
    const { setResumeStep } = useResumeStep();
    const [educations, setEducations] = useState([]);
    const [eduId, setEduId] = useState(1);
    const [selectedDegree, setSelectedDegree] = useState('');
    const [customDegree, setCustomDegree] = useState('');

    const years = [];
    const currentYear = moment().year();
    for (let year = currentYear; year >= 1950; year--) {
        years.push(<option value={year} key={year}>{year}</option>);
    }

    // close the confirmation dialog 
    const handleDailogClose = () => {
        setDailogOpen(false);
    };

    const handleDegreeChange = (e) => {
        if (e.target.value === 'other') {
            setCustomDegree('');
        }
        setSelectedDegree(e.target.value);
    };

    useEffect(() => {
        if (acvResume) {
            axios.get(serverURL.SERVER_URL + 'education/' + acvResume.resume_id, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': '$2y$10$e6tbgQSbvmC6X7us57XsIeuBZT7eZYqiu/zDTKjKNX4KL9wQEOvEG'
                }
            }).then((result) => {
                const data = result.data;
                setEducations(data.educations);
            })
        }
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if (validate('input', 'School name', data.get('name')) == -1) {
            return
        } if (validate('input', 'School location', data.get('location')) == -1) {
            return
        } if (validate('input', 'Degree', selectedDegree) == -1) {
            return
        }

        setFormError('')
        const education = {
            id: eduId,
            school_name: data.get('name'),
            school_location: data.get('location'),
            degree: selectedDegree == 'Other' ? data.get('other-degree') : selectedDegree,
            field_of_study: data.get('field_of_study'),
            grd_month: data.get('grd-month') == 'Month' ? '' : data.get('grd-month'),
            grd_year: data.get('grd-year') == 'Year' ? '' : data.get('grd-year')
        };
        setEducations([...educations, education]);
        setEduId(eduId + 1);
        const form = event.currentTarget;
        const formInputs = form.querySelectorAll('input, select');
        formInputs.forEach(input => input.value = '');
    }

    const handleDeleteEducation = (id) => {
        const updatedEducations = educations.filter(education => education.id !== id);
        setEducations(updatedEducations);
    };
    const cehckEducations = () => {
        if (educations.length < 1) {
            setDailogOpen(true);
        } else {
            nextSkills();
        }
    };
    const nextSkills = () => {
        setDailogOpen(false);
        setBackdropOpen(true);
        try {
            axios.post(serverURL.SERVER_URL + 'educations/' + acvResume.resume_id, { educations: educations }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then((result) => {
                const data = result.data;
                if (result.status === 200) {
                    setResumeStep(4);
                    navigate('/resume/skills')
                }
            }).catch(function (error) {
                setError(error.message)
                setBackdropOpen(false);
            })
        } catch (error) {
            setBackdropOpen(false);
        }
    }

    return (
        <>
            <div className="main-content float-end col-lg-10 col-12">
                <div className="title">
                    Tell us about your education
                </div>
                <div className="sub-title">
                    Enter your education experience so far, even if you are a current student or did not graduate.
                </div>

                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className=" mb-4">
                            <div className="card-body">
                                <form onSubmit={handleSubmit} method='post'>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="mb-4">
                                                <label htmlFor="name" className="form-label">School Name</label>
                                                <input type="text" name={'name'} placeholder="Type here *" className={`form-control ${formError.type == 'School name' && 'error'}`} id="name" />
                                                {
                                                    formError.type == 'School name' && (
                                                        <label className="text-danger">{formError.error}</label>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-4">
                                                <label htmlFor="location" className="form-label">School Location</label>
                                                <input type="text" name={'location'} placeholder="Type here *" className={`form-control ${formError.type == 'School location' && 'error'}`} id="location" />
                                                {
                                                    formError.type == 'School location' && (
                                                        <label className="text-danger">{formError.error}</label>
                                                    )
                                                }
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="mb-4">
                                                <label className="form-label">Degree</label>
                                                <select value={selectedDegree} onChange={handleDegreeChange} className={`form-select ${formError.type == 'Degree' && 'error'}`} >
                                                    <option value="">Select a degree...</option>
                                                    <option value="High School Diploma">High School Diploma</option>
                                                    <option value="Bachelor of Arts">Bachelor of Arts</option>
                                                    <option value="Bachelor of Science">Bachelor of Science</option>
                                                    <option value="BBA">BBA</option>
                                                    <option value="Master of Arts">Master of Arts</option>
                                                    <option value="Master of Science">Master of Science</option>
                                                    <option value="MBA">MBA</option>
                                                    <option value="M.D.">M.D.</option>
                                                    <option value="Ph.D.">Ph.D.</option>
                                                    <option value="No Degree">No Degree</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {
                                                    formError.type == 'Degree' && (
                                                        <label className="text-danger">{formError.error}</label>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            {selectedDegree === 'Other' && (
                                                <div className="mb-4">
                                                    <label htmlFor="product_name" className="form-label">Degree</label>
                                                    <input type="text" placeholder="Type here" value={customDegree} onChange={(e) => setCustomDegree(e.target.value)} className="form-control" name={'other-degree'} />
                                                </div>
                                            )}

                                        </div>
                                        <div className="col-lg-6">
                                            <div className="mb-4">
                                                <label htmlFor="product_name" className="form-label">Field Of Study</label>
                                                <input type="text" placeholder="Type here" className="form-control" name={'field_of_study'} />
                                            </div>
                                        </div>

                                        <div className="mb-4 col-lg-3">
                                            <label className="form-label">Graduation Date </label>
                                            <select className="form-select" name={'grd-month'} >
                                                <option> Month </option>
                                                <option value={'01'}> JANUARY </option>
                                                <option value={'02'}> FEBERWARY </option>
                                                <option value={'03'}> MARCH </option>
                                                <option value={'04'}> APRIL </option>
                                                <option value={'05'}> MAY </option>
                                                <option value={'06'}> JUNE </option>
                                                <option value={'07'}> JULY </option>
                                                <option value={'08'}> AGUSTE </option>
                                                <option value={'09'}> SEPTEMBER </option>
                                                <option value={'10'}> OCTOBAR </option>
                                                <option value={'11'}> NEVEMBER </option>
                                                <option value={'12'}> DECEMBER </option>
                                            </select>
                                        </div>
                                        <div className="mb-4 col-lg-3">
                                            <label className="form-label">&nbsp;</label>
                                            <select className="form-select" name={'grd-year'}>
                                                <option> Year </option>
                                                {years}
                                            </select>
                                        </div>
                                        <div className="col-12 text-end">
                                            <button className='btn btn-app-primary'>Add <Plus style={{ fontSize: '18px' }} /></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th scope="col">School Name</th>
                                <th scope="col">School Location</th>
                                <th scope="col">Degree</th>
                                <th scope="col">Field of Study</th>
                                <th scope="col">Graducation Date</th>
                                <th scope="col"> Action </th>
                            </tr>
                        </thead>
                        <tbody>
                            {educations.map((edu, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{edu.school_name}</td>
                                    <td>{edu.school_location}</td>
                                    <td>{edu.degree}</td>
                                    <td>{edu.field_of_study}</td>
                                    <td>{edu.grd_month} - {edu.grd_year}</td>
                                    <td>
                                        <a className="" onClick={() => handleDeleteEducation(edu.id)}><Trash /></a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="col-12 text-end">
                    <Link to={'/resume/work-history'} onClick={() => setResumeStep(2)} className='btn btn-outline-app-primary btn-circle me-1'> Previous</Link>
                    <button className='btn btn-dark btn-circle' onClick={cehckEducations}> Next : Skills</button>
                </div>
            </div>

            {/* confirm place order dialog */}
            <Dialog
                open={dailogOpen}
                onClose={handleDailogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Warning, you didn't add educations!"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleDailogClose}>Add</Button>
                    <Button onClick={nextSkills}>
                        Containue
                    </Button>
                </DialogActions>
            </Dialog>
            {/* end confirm place order dialog */}

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default Education