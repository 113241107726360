import React from 'react'
import { Link } from 'react-router-dom';

import Logo from '../../assets/img/logo/Logo-white-sm.svg';

const Footer = ({ children }) => {
    return (
        <footer className="footer-area bg-img">
            <div className="footer-content-area spec">
                <div className="mx-5">
                    <div className="row mx-5">
                        <div className="col-12 col-lg-4 col-md-6">
                            <div className="footer-copywrite-info">
                                {/* Copywrite  */}
                                <div className="copywrite_text fadeInUp" data-wow-delay="0.2s">
                                    <div className="footer-logo">
                                        <a href="#"><img src={Logo} alt="logo" /> ALIEN CV </a>
                                    </div>
                                    <p>With the ALIEN CV website, you can easily create a professional and attractive resume. This website allows you to create a unique and high-quality CV using various templates and tools. </p>
                                </div>
                                {/* Social Icon  */}
                                <div className="footer-social-info fadeInUp" data-wow-delay="0.4s">
                                    <a href="#" ><i className="fa fa-facebook" aria-hidden="true"></i></a>
                                    <a href="#"> <i className="fa fa-twitter" aria-hidden="true"></i></a>
                                    <a href="#"><i className="fa fa-google-plus" aria-hidden="true"></i></a>
                                    <a href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                                    <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-3 col-md-6 ps-lg-5">
                            <div className="contact_info_area d-sm-flex justify-content-between">
                                {/* Content Info  */}
                                <div className="contact_info mt-x text-center fadeInUp" data-wow-delay="0.3s">
                                    <h5>QUICK LINKS</h5>
                                    <Link to={'/'}>
                                        <p>Home</p>
                                    </Link>
                                    <Link to={'/about'}>
                                        <p>About</p>
                                    </Link>
                                    <Link to={'/templates'}>
                                        <p>Templates</p>
                                    </Link>
                                    <Link to={'/contact'}>
                                        <p>Contact</p>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-2 col-md-6 ">
                            {/* Content Info  */}
                            <div className="contact_info_area d-sm-flex justify-content-between">
                                <div className="contact_info mt-s text-center fadeInUp" data-wow-delay="0.2s">
                                    <h5>INFORMATION</h5>
                                    <Link to={'/privacy-policy'}>
                                        <p>Privacy Policy</p>
                                    </Link>
                                    <Link to={'/terms-and-condition'}>
                                        <p>Terms & Condition</p>
                                    </Link>
                                </div>
                            </div>
                        </div>


                        <div className="col-12 col-lg-3 col-md-6 ">
                            <div className="contact_info_area d-sm-flex justify-content-between">
                                {/* Content Info  */}
                                <div className="contact_info mt-s text-center fadeInUp" data-wow-delay="0.4s">
                                    <h5>CONTACT US</h5>
                                    <p>Mailing Address:xx00 E. Union Ave</p>
                                    <p>Suite 1100. Denver, CO 80237</p>
                                    <p>+33 758 061 729</p>
                                    <p>support@aliencv.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </footer>
    )
}

export default Footer