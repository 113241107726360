import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';

//import Icons
import LogoWhite from '../../assets/img/logo/Logo-white-sm.svg';
import LogoYellow from '../../assets/img/logo/Logo-Yellow.svg';
import cartIcon from '../../assets/img/svgs/cart.svg';
import userIcon from '../../assets/img/svgs/user.svg';
import { Border, Check } from "react-bootstrap-icons";
import './Header.css';

// providers
import { useResumeStep } from '../../context/resume-step/ResumeStepContext';

// import custom hook of authentication
import { useAuth } from '../../context/auth/AuthContext';

const Header = () => {

    const { user, setUser } = useAuth();

    const { resumeStep, setResumeStep } = useResumeStep();

    // get the route name
    const location = useLocation();
    const currentRoute = location.pathname.split('/')[2];
    // end of route name

    useEffect(() => {
        if (currentRoute == 'personal-info') {
            setResumeStep(1)
        }else if (currentRoute == 'work-history') {
            setResumeStep(2)
        }else if (currentRoute == 'education') {
            setResumeStep(3)
        }else if (currentRoute == 'skills') {
            setResumeStep(4)
        }else if (currentRoute == 'language') {
            setResumeStep(5)
        }else if (currentRoute == 'references') {
            setResumeStep(6)
        }else if (currentRoute == 'summary') {
            setResumeStep(7)
        }else{
            setResumeStep(8)
        }
    }, [])

    // logout comfirmation dailog c
    const [dailogOpen, setDailogOpen] = useState(false);

    // logout user
    const handleLogout = async () => {
        localStorage.removeItem('royabranduser');
        window.location.href = '/';
    };

    // logout confirmation dailog
    const handleDailogClose = () => {
        setDailogOpen(false);
    }

    function getClassForResumeStep(resumeStep, step) {
        if (resumeStep === step) {
            return "active";
        } else if (resumeStep < step) {
            return "disabled";
        } else {
            return "complete";
        }
    }
    return (
        <>
            <header className="app-bar p-3 d-flex justify-content-between ">
                <div>
                    <img src={LogoWhite} style={{ height: '50px' }} alt="Logo" className='ms-3' />
                </div>
                <div className="user-icon">
                    <i className="bi bi-person-circle fs-3"></i>
                </div>
            </header>

            <header className="bg-light d-block d-lg-none">
                <div className="container">
                    <div className="row bs-wizard justify-content-center" style={{ borderBottom: '0' }}>
                        <div className={`${getClassForResumeStep(resumeStep, 1)} col-1 bs-wizard-step`}>
                            <div className="text-center bs-wizard-stepnum">&nbsp;</div>
                            <div className="progress"><div className="progress-bar progress-bar-striped"></div></div>
                            <a href="#" className="bs-wizard-dot"></a>
                        </div>

                        <div className={`${getClassForResumeStep(resumeStep, 2)} col-1 bs-wizard-step`}>
                            <div className="text-center bs-wizard-stepnum">&nbsp;</div>
                            <div className="progress"><div className="progress-bar progress-bar-striped"></div></div>
                            <a href="#" className="bs-wizard-dot"></a>
                        </div>

                        <div className={`${getClassForResumeStep(resumeStep, 3)} col-1 bs-wizard-step`}>
                            <div className="text-center bs-wizard-stepnum">&nbsp;</div>
                            <div className="progress"><div className="progress-bar"></div></div>
                            <a href="#" className="bs-wizard-dot"></a>
                        </div>

                        <div className={`${getClassForResumeStep(resumeStep, 4)} col-1 bs-wizard-step`}>
                            <div className="text-center bs-wizard-stepnum">&nbsp;</div>
                            <div className="progress"><div className="progress-bar"></div></div>
                            <a href="#" className="bs-wizard-dot"></a>
                        </div>
                        <div className={`${getClassForResumeStep(resumeStep, 5)} col-1 bs-wizard-step`}>
                            <div className="text-center bs-wizard-stepnum">&nbsp;</div>
                            <div className="progress"><div className="progress-bar"></div></div>
                            <a href="#" className="bs-wizard-dot"></a>
                        </div>
                        <div className={`${getClassForResumeStep(resumeStep, 6)} col-1 bs-wizard-step`}>
                            <div className="text-center bs-wizard-stepnum">&nbsp;</div>
                            <div className="progress"><div className="progress-bar"></div></div>
                            <a href="#" className="bs-wizard-dot"></a>
                        </div>
                        <div className={`${getClassForResumeStep(resumeStep, 7)} col-1 bs-wizard-step`}>
                            <div className="text-center bs-wizard-stepnum">&nbsp;</div>
                            <div className="progress"><div className="progress-bar"></div></div>
                            <a href="#" className="bs-wizard-dot"></a>
                        </div>
                        <div className={`${getClassForResumeStep(resumeStep, 8)} col-1 bs-wizard-step`}>
                            <div className="text-center bs-wizard-stepnum">&nbsp;</div>
                            <div className="progress"><div className="progress-bar"></div></div>
                            <a href="#" className="bs-wizard-dot"></a>
                        </div>
                    </div>
                </div>
            </header >

            <div className="left-panel col-lg-2 d-none d-lg-block" >
                <div className='logo'>
                    <img src={LogoWhite} alt="Logo" style={{ width: '80px' }} />
                </div>
                <div className="steps">
                    <div className={`${resumeStep == 1 && " step-active"} step`}>
                        <div>
                            {
                                resumeStep > 1
                                    ?
                                    (
                                        <div className="circle"><Check /></div>
                                    )
                                    :
                                    (
                                        <div className="circle">1</div>
                                    )
                            }
                        </div>
                        <div>
                            <div className="title">Personal Info</div>
                        </div>
                    </div>
                    <div className={`${resumeStep == 2 && " step-active"} step`}>
                        <div>
                            {
                                resumeStep > 2
                                    ?
                                    (
                                        <div className="circle"><Check /></div>
                                    )
                                    :
                                    (
                                        <div className="circle">2</div>
                                    )
                            }
                        </div>
                        <div>
                            <div className="title">Work History</div>
                        </div>
                    </div>
                    <div className={`${resumeStep == 3 && " step-active"} step`}>
                        <div>
                            {
                                resumeStep > 3
                                    ?
                                    (
                                        <div className="circle"><Check /></div>
                                    )
                                    :
                                    (
                                        <div className="circle">3</div>
                                    )
                            }
                        </div>
                        <div>
                            <div className="title">Education</div>
                        </div>
                    </div>
                    <div className={`${resumeStep == 4 && " step-active"} step`}>
                        <div>
                            {
                                resumeStep > 4
                                    ?
                                    (
                                        <div className="circle"><Check /></div>
                                    )
                                    :
                                    (
                                        <div className="circle">4</div>
                                    )
                            }
                        </div>
                        <div>
                            <div className="title">Skills</div>
                        </div>
                    </div>
                    <div className={`${resumeStep == 5 && " step-active"} step`}>
                        <div>
                            {
                                resumeStep > 5
                                    ?
                                    (
                                        <div className="circle"><Check /></div>
                                    )
                                    :
                                    (
                                        <div className="circle">5</div>
                                    )
                            }
                        </div>
                        <div>
                            <div className="title">Language</div>
                        </div>
                    </div>
                    <div className={`${resumeStep == 6 && " step-active"} step`}>
                        <div>
                            {
                                resumeStep > 6
                                    ?
                                    (
                                        <div className="circle"><Check /></div>
                                    )
                                    :
                                    (
                                        <div className="circle">6</div>
                                    )
                            }
                        </div>
                        <div>
                            <div className="title">References</div>
                        </div>
                    </div>
                    <div className={`${resumeStep == 7 && " step-active"} step`}>
                        <div>
                            {
                                resumeStep > 7
                                    ?
                                    (
                                        <div className="circle"><Check /></div>
                                    )
                                    :
                                    (
                                        <div className="circle">7</div>
                                    )
                            }
                        </div>
                        <div>
                            <div className="title">Summury</div>
                        </div>
                    </div>
                    <div className={`${resumeStep == 8 && " step-active"} step`}>
                        <div>
                            <div className="circle">8</div>
                        </div>
                        <div>
                            <div className="title">Finalize</div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Header