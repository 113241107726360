import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './SignIn.css'


// providers
import { useAuth } from '../../../context/auth/AuthContext';
import { ResProfileContext } from '../../../context/restaurant/ProfileContext';
import { ValidationContext } from '../../../context/validation/ValidationContext';

import * as serverURL from '../../../config/Constants'

// MUI library
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';


export default function SignIn() {
  const { user, setUser } = useAuth();
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // import profile context
  const { restaurantProfile } = useContext(ResProfileContext);

  // import validtaion context params
  const { validate, setFormErrorEmpty, formError } = useContext(ValidationContext);

  // if user is  logged in, redirect to home page
  useEffect(function () {
    if (user) {
      navigate('/')
    }
  })

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrorEmpty()
    const data = new FormData(event.currentTarget);
    const body = {
      email: data.get('email'),
      password: data.get('password'),
    };
    if (validate('email', 'Email', body.email) == -1) {
      return
    }
    if (validate('input', 'Password', body.password) == -1) {
      return
    }

    setLoading(true)
    try {
      axios.post(serverURL.SERVER_URL + 'auth/login', body, {
        headers: {
          'Content-Type': 'application/json',
        }
      }).then((result) => {
        const data = result.data;
        if (data.status == true) {
          // if user is verified
          if (data.user.email_verified_at != null) {
            setUser(data.user);
            setLoading(false)
            localStorage.removeItem('rbranduseremail');
            localStorage.setItem('rbrandusertoken', data.token);
            window.location.href = '/profile'
          } else {
            localStorage.setItem('rbranduseremail', data.user.email);
            navigate('/email/verify/', { state: { verify: 'true' } })
          }
          // 
        } else {
        }
      }).catch(function (error) {
        setError(error.response.data.message)
        setLoading(false)
      })
    } catch (error) {
    }
  };

  return (
    <>
      <br /><br />
      <div className="container contact_container" >
        <div className="col-lg-10 m-auto mt-5">
          <div className="row">
            <div className="col-lg-6">
              <div className="login_wrap widget-taber-content p-30 background-white border-radius-10 mb-md-5 mb-lg-0 mb-sm-5">
                <div className="padding_eight_all bg-white">
                  <div className="heading_s1">
                    <h4 className="mb-30">SIGN IN</h4>
                    {error.length > 1 && (
                      <Typography className='text-danger fs-4'>
                        {error}
                      </Typography>
                    )}
                    <p>If you have an account with us, please log in.</p>
                  </div>
                  <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <div className="form-group">
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        error={formError.type == 'Email' ? true : false}
                        className={'form_input'}
                      />
                    </div>
                    <div className="form-group">
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        error={formError.type == 'Password' ? true : false}
                        className={'form_input'}
                      />
                    </div>
                    <div className="fl f-aic f-jcsb">
                      <Button
                        type="submit"
                        variant="contained"
                        startIcon={loading && <CircularProgress size={20} sx={{ color: 'grey.500' }} />}
                        disabled={loading}
                        style={{ backgroundColor: 'black', color: 'white' }}
                      >
                        Sign In
                      </Button>

                      <Link to="/forgot-password" variant="body2" >
                        Forgot password?
                      </Link>
                    </div>
                  </Box>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="login_wrap widget-taber-content p-30 background-white border-radius-5">
                <div className="padding_eight_all bg-white">
                  <div className="heading_s1">
                    <h4 className="mb-30">NEW CUSTOMER?</h4>
                  </div>
                  <p className="mb-50 font-sm">
                    Registering for this site allows you to access your order status and history. We’ll get a new account set up for you in no time. For this will only ask you for information necessary to make the purchase process faster and easier
                  </p>
                  <div className="fl f-aic f-jcsb mt-20">
                    <Link to="/signup" className="btn btn-dark btn-hover">
                      CREATE AN ACCOUNT
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
} 