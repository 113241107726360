import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';

// import routes
import router from './route/router';

// import providers
import { CartContextProvider } from './context/cart/CartContext'
import { SearchContextProvider } from './context/search/SearchContext'
import { ResProfileContextProvider } from './context/restaurant/ProfileContext'
import { NotificationContextProvider } from './context/notification/NotificationContext'
import { ResumeStepContextProvider } from './context/resume-step/ResumeStepContext'
import { ValidationContextProvider } from './context/validation/ValidationContext'
import { AuthProvider } from './context/auth/AuthContext'
import { ACVResumeProvider } from './context/resume/ResumeContext'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ACVResumeProvider>
        <ValidationContextProvider>
          <ResumeStepContextProvider>
            <NotificationContextProvider>
              <ResProfileContextProvider>
                <SearchContextProvider>
                  <CartContextProvider>
                    <RouterProvider router={router} />
                  </CartContextProvider>
                </SearchContextProvider>
              </ResProfileContextProvider>
            </NotificationContextProvider>
          </ResumeStepContextProvider>
        </ValidationContextProvider>
      </ACVResumeProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
