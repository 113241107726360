import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ResProfileContext } from '../../context/restaurant/ProfileContext';
import { useAuth } from '../../context/auth/AuthContext';

import Logo from '../../assets/img/logo/Logo-Yellow.svg';

const Header = () => {

    const navigate = useNavigate();

    // get the route name
    const location = useLocation();
    const currentRoute = location.pathname.split('/')[1];
    // end of route name

    const { user } = useAuth();

    const { id } = useParams();

    useEffect(function () {
        if (user) {
            navigate('/home/' + id);
        }
    }, [])

    return (
        <>
            <header className="app-bar p-3 d-flex justify-content-between ">
                <div>
                    <img src={Logo} alt="Logo" />
                </div>
                <div className="user-icon">
                    <i className="bi bi-person-circle fs-3"></i>
                </div>
            </header>



        </>
    )
}

export default Header