import React, { useState, createContext, useContext } from 'react';

const ResumeStepContext = createContext(null);

export const ResumeStepContextProvider = (props) => {

    const [resumeStep, setResumeStep] = useState(1);

    const contextValue = { resumeStep, setResumeStep };

    return <ResumeStepContext.Provider value={contextValue}>{props.children}</ResumeStepContext.Provider>
}

export const useResumeStep = () => {
	return useContext(ResumeStepContext);
};
