import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import './Skill.css';

import { Link, useNavigate } from 'react-router-dom';
import { Border, ChevronRight, PlugFill, Plus, Trash } from "react-bootstrap-icons";

// components
import * as serverURL from '../../../config/Constants'

// providers
import { useResumeStep } from '../../../context/resume-step/ResumeStepContext';
import { ValidationContext } from '../../../context/validation/ValidationContext';
import { useACVResume } from '../../../context/resume/ResumeContext';

// MUI library
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Rating from '@mui/material/Rating';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Tooltip from '@mui/material/Tooltip';
import Backdrop from '@mui/material/Backdrop';


const Skill = () => {
    const navigate = useNavigate();
    const { acvResume, setAcvResume } = useACVResume();
    const [dailogOpen, setDailogOpen] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [error, setError] = useState({});
    const { setResumeStep } = useResumeStep();
    const [skillTextboxes, setSkillTextboxes] = useState(['']);;

    // import validtaion context params
    const { validate, setFormErrorEmpty, formError, setFormError } = useContext(ValidationContext);


    const handleAddTextBox = (e) => {
        e.preventDefault();
        setSkillTextboxes([...skillTextboxes, '']);
    };

    const handleRemoveTextBox = (index) => {
        const updatedTextboxes = skillTextboxes.filter((_, i) => i !== index);
        setSkillTextboxes(updatedTextboxes);
    };

    const handleChange = (index, value) => {
        const updatedTextboxes = [...skillTextboxes];
        updatedTextboxes[index] = value;
        setSkillTextboxes(updatedTextboxes);
    };

    // close the confirmation dialog 
    const handleDailogClose = () => {
        setDailogOpen(false);
    };

    useEffect(() => {
        if (acvResume) {
            axios.get(serverURL.SERVER_URL + 'skills/' + acvResume.resume_id, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': '$2y$10$e6tbgQSbvmC6X7us57XsIeuBZT7eZYqiu/zDTKjKNX4KL9wQEOvEG'
                }
            }).then((result) => {
                const data = result.data;
                if (data.skills.length > 0) {
                    setSkillTextboxes(data.skills);
                }
            })
        }
    }, [])

    const cehckSkills = () => {
        if (skillTextboxes.length < 1) {
            setDailogOpen(true);
        } else {
            nextLanguages();
        }
    };
    const nextLanguages = () => {
        setDailogOpen(false);
        setBackdropOpen(true);
        try {
            axios.post(serverURL.SERVER_URL + 'skills/' + acvResume.resume_id, { skills: skillTextboxes }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then((result) => {
                const data = result.data;
                if (result.status === 200) {
                    setResumeStep(5);
                    navigate('/resume/language')
                }
            }).catch(function (error) {
                setBackdropOpen(false);
                setError(error.message)
            })
        } catch (error) {
            setBackdropOpen(false);
        }
    };


    return (
        <>
            <div className="main-content float-end col-lg-10 col-12">
                <div className="title">
                    What skills would you like to highlight?
                </div>
                <div className="sub-title">
                    Write down your skills.
                </div>

                <Container className='mt-2' fixed>
                    <Grid container spacing={2}>
                        {skillTextboxes.map((value, index) => (
                            <>
                                <Grid item md={6} xs={10} className='text-start'>
                                    <TextField
                                        className={`col-12`}
                                        id="outlined-required"
                                        label="Skill"
                                        name='firstname'
                                        value={value}
                                        onChange={(e) => handleChange(index, e.target.value)}
                                        
                                    />
                                </Grid>
                                <Grid item md={6} xs={2} className='text-start pt-4 text-dark fs-6' onClick={() => handleRemoveTextBox(index)}>
                                    <Trash />
                                </Grid>
                            </>
                        ))}

                        <Grid item md={6} xs={12} className='text-end td-none'>
                            <button onClick={handleAddTextBox} className='btn btn-link text-decoration-none'><Plus className='fs-5' /> Add one more</button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className='text-end'>
                        <Link to={'/resume/education'} onClick={() => setResumeStep(3)} className='btn btn-outline-app-primary btn-circle me-1'> Previous</Link>
                        <button className='btn btn-dark btn-circle' onClick={nextLanguages}> Next : Language </button>
                    </Grid>
                </Container>
            </div>
            {/* confirm place order dialog */}
            <Dialog
                open={dailogOpen}
                onClose={handleDailogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Warning, you didn't add your skills!"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleDailogClose}>Add</Button>
                    <Button onClick={nextLanguages}>
                        Containue
                    </Button>
                </DialogActions>
            </Dialog>
            {/* end confirm place order dialog */}

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default Skill