import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';

import { Heart, Star } from "react-bootstrap-icons";
import banner2 from '../assets-website/img/core-img/banner2.png';
import CVIcon from '../assets-website/img/core-img/cv.png';
import imgCustom from '../assets-website/img/core-img/custom.png';
import imgDollar from '../assets-website/img/svg/img-dollar.svg';
import imgD1 from '../assets-website/img/icons/d1.png';
import imgD2 from '../assets-website/img/icons/d2.png';
import imgD3 from '../assets-website/img/icons/d3.png';
import iconF1 from '../assets-website/img/icons/f1.png';
import iconF2 from '../assets-website/img/icons/f2.png';
import iconF3 from '../assets-website/img/icons/f3.png';
import iconF4 from '../assets-website/img/icons/f4.png';
import iconF5 from '../assets-website/img/icons/f5.png';
import iconF6 from '../assets-website/img/icons/f6.png';
import iconCheck from '../assets-website/img/icons/check.png';
import imgDemo1 from '../assets-website/img/demos/demo-1.png';
import imgDemo2 from '../assets-website/img/demos/demo-2.png';
import imgDemo3 from '../assets-website/img/demos/demo-3.png';


const Home = () => {

    const { id } = useParams();


    return (
        <>

            {/* <!-- ##### Welcome Area Start ##### --> */}
            <section className="welcome_area demo2 flex align-items-center">
                <div className="container">
                    <div className="row align-items-center">
                        {/* <!-- Welcome Content --> */}
                        <div className="col-12 col-lg-6 col-md-12">
                            <div className="welcome-content v2">
                                <div className="promo-section">
                                    <div className="integration-link light">
                                        <span className="integration-icon">
                                            <img src={imgDollar} width="24" height="24" alt="" />
                                        </span>
                                        <span className="integration-text">Discover The Easiest ways to Build Your CV!</span>
                                    </div>
                                </div>
                                <h1 className="wow fadeInUp" data-wow-delay="0.2s">Online CV Builder With Creative Templates.</h1>
                                <p className="wow fadeInUp text-secondary" data-wow-delay="0.3s">Our Perfect resume builder takes the hassle out of resume writing. Choose from several templates and follow easy prompts to create the perfect job-ready resume.</p>
                                <div className="dream-btn-group wow fadeInUp" data-wow-delay="0.4s">
                                    <Link to={'/templates'} className="btn dream-btn green-btn me-3">Choose Template</Link>
                                    <Link to={'/contact'} className="btn dream-btn green-btn"> contact us</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-md-12">
                            <div className="banner-box">
                                <img src={banner2} alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {/* <!-- ##### Welcome Area End ##### --> */}

            <div className="clearfix"></div>

            <section className="demo-video feat section-padding-100 bub-left">
                <div className="container">

                    <div className="row align-items-center">

                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="services-block-four">
                                <div className="inner-box">
                                    <div className="icon-img-box">
                                        <img src={imgD1} alt="" />
                                    </div>
                                    <h3><a href="#">User-Friendly Interface</a></h3>
                                    <div className="text">Our online CV builder website features an intuitive and user-friendly interface, making it easy for users to create professional resumes with just a few clicks.</div>

                                </div>
                            </div>
                            <div className="services-block-four">
                                <div className="inner-box">
                                    <div className="icon-img-box">
                                        <img src={imgD2} alt="" />
                                    </div>
                                    <h3><a href="#"> Customizable Templates</a></h3>
                                    <div className="text">Choose from a wide range of customizable templates to create a unique and visually appealing CV that highlights your skills and experience.</div>

                                </div>
                            </div>
                            <div className="services-block-four" style={{ marginBottom: 0 }}>
                                <div className="inner-box">
                                    <div className="icon-img-box">
                                        <img src={imgD3} alt="" />
                                    </div>
                                    <h3><a href="#">Export Options</a></h3>
                                    <div className="text">Easily export your completed CV in multiple formats, such as PDF.</div>

                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="who-we-contant mt-s">
                                <div className="dream-dots">
                                    <span></span><span></span><span></span><span></span><span></span><span></span><span></span>
                                </div>
                                <h4>Why Choose Our Platform?</h4>
                                <p className='text-secondary '>
                                    As a reputable and trustworthy website for creating professional resumes, we have a team of experienced professionals who can help you craft an outstanding and engaging CV.
                                </p>
                                <p className='text-secondary '> We use modern design and unique templates to showcase amazing resumes that allow you to easily demonstrate your attractiveness and professionalism.</p>
                                <Link className="btn dream-btn mt-30" to={'/get-start'}>lets build your cv</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* About Us Area Start  */}
            <section className="about-us-area section-padding-100 clearfix">
                <div className="container">
                    <div className="row align-items-center">

                        <div className="col-12 col-lg-6">
                            <div className="who-we-contant">
                                <div className="dream-dots">
                                    <span></span><span></span><span></span><span></span><span></span><span></span><span></span>
                                </div>
                                <h4 className="bold">We Deliver The Best</h4>
                                <div className="list-wrap align-items-center">
                                    <div className="row">

                                        <div className="col-md-12">
                                            <div className="side-feature-list-item">
                                                <img src={iconCheck} className="check-mark-icon" alt="" />
                                                <div className="foot-c-info">Proven CV Templates to increase Hiring Chance</div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="side-feature-list-item">
                                                <img src={iconCheck} className="check-mark-icon" alt="" />
                                                <div className="foot-c-info">Creative and Clean Templates Design</div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="side-feature-list-item">
                                                <img src={iconCheck} className="check-mark-icon" alt="" />
                                                <div className="foot-c-info">Easy and Intuitive Online CV Builder</div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="side-feature-list-item">
                                                <img src={iconCheck} className="check-mark-icon" alt="" />
                                                <div className="foot-c-info">Free to use. Developed by hiring professionals.</div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="side-feature-list-item">
                                                <img src={iconCheck} className="check-mark-icon" alt="" />
                                                <div className="foot-c-info">Fast Easy CV and Resume Formatting</div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="side-feature-list-item">
                                                <img src={iconCheck} className="check-mark-icon" alt="" />
                                                <div className="foot-c-info">Recruiter Approved Phrases.</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-lg-6">
                            <div className="welcome-meter wow fadeInUp mt-s" data-wow-delay="0.3s">
                                <img src={CVIcon} className="center-block" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* About Us Area End  */}

            <div className="clearfix"></div>

            {/* Area start  */}
            <section className="container section-padding-0-100">
                <div className="subscribe">
                    <div className="row align-items-center  text-right" >
                        <img src={imgCustom} alt="" className="custom" style={{ marginLeft: '-8px', marginTop: '1px' }} />
                        <div className="col-lg-5 offset-lg-3 col-md-9 col-xs-12">
                            <h2 className="bold mb-0">Do you Need a Complete Custom CV Template?</h2>
                        </div>
                        <div className="col-lg-3 offset-lg-1 col-md-3 col-sm-12 text-right ">
                            <Link to={'/contact'} className="button mt-s">Send a Request</Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* Area End  */}

            <section className="demo section-padding-100 ring-bg">
                <div className="container">
                    <div className="section-heading text-center">
                        <div className="dream-dots justify-content-center">
                            <span></span><span></span><span></span><span></span><span></span><span></span><span></span>
                        </div>
                        <h2 className="bold">Our Creative Templates</h2>
                        <p className='text-secondary fw-bold'>Easily design your resume in a unique and creative way using our templates.</p>
                    </div>
                    <div className="row">

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="demo-item">
                                <a href="template-preview.html"><img src={imgDemo1} alt="demo"
                                    className="img-responsive" /></a>
                                <div className="preview-btn-wrapper text-center">
                                    <Link to={'templates'} className="preview-demo">See template <i
                                        className="fa fa-long-arrow-right"></i></Link>
                                    <Link to={'/get-start'} className="preview-demo v2">Use template <i
                                        className="fa fa-long-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="demo-item">
                                <a href="template-preview.html"><img src={imgDemo2} alt="demo"
                                    className="img-responsive" /></a>
                                <div className="preview-btn-wrapper text-center">
                                    <Link to={'templates'} className="preview-demo">See template <i
                                        className="fa fa-long-arrow-right"></i></Link>
                                    <Link to={'/get-start'} className="preview-demo v2">Use template <i
                                        className="fa fa-long-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="demo-item">
                                <a href="template-preview.html"><img src={imgDemo3} alt="demo"
                                    className="img-responsive" /></a>
                                <div className="preview-btn-wrapper text-center">
                                    <Link to={'templates'} className="preview-demo">See template <i
                                        className="fa fa-long-arrow-right"></i></Link>
                                    <Link to={'/get-start'} className="preview-demo v2">Use template <i
                                        className="fa fa-long-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>

            {/* Our features Area Start  */}
            <section className="our_services_area section-padding-100-70" id="services">
                <div className="container">

                    <div className="section-heading text-center">
                        {/* <!-- Dream Dots --> */}
                        <div className="dream-dots justify-content-center wow fadeInUp" data-wow-delay="0.2s">
                            <span></span><span></span><span></span><span></span><span></span><span></span><span></span>
                        </div>
                        <h2 className="wow fadeInUp" data-wow-delay="0.3s">Our Main Features</h2>
                        <p className="wow fadeInUp text-secondary fw-bold" data-wow-delay="0.4s">Explore our website for creating CVs and discover our main features.</p>
                    </div>


                    <div className="row">
                        <div className="col-12 col-sm-6 col-lg-4">
                            {/* <!-- Content --> */}
                            <div className="service_single_content text-center mb-100 wow fadeInUp" data-wow-delay="0.2s">
                                {/* <!-- Icon --> */}
                                <div className="service_icon">
                                    <img src={iconF1} alt="" />
                                </div>
                                <h6>Proven CV Templates to increase Hiring Chance</h6>
                                <p className='text-secondary'>Lorem ipsum dolor sit amet, consecte adipiscing elit. Nulla neque quam, maxim us ut accumsan
                                    ut, posuere sit Lorem ipsum adipiscing elit.</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                            {/* <!-- Content --> */}
                            <div className="service_single_content text-center mb-100 wow wow fadeInUp" data-wow-delay="0.3s">
                                {/* <!-- Icon --> */}
                                <div className="service_icon">
                                    <img src={iconF2} alt="" />
                                </div>
                                <h6>Creative, Modern and Clean Templates Design</h6>
                                <p className='text-secondary'>Lorem ipsum dolor sit amet, consecte adipiscing elit. Nulla neque quam, maxim us ut accumsan
                                    ut, posuere sit Lorem ipsum adipiscing elit.</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                            {/* <!-- Content --> */}
                            <div className="service_single_content text-center mb-100 wow fadeInUp" data-wow-delay="0.4s">
                                {/* <!-- Icon --> */}
                                <div className="service_icon">
                                    <img src={iconF3} alt="" />
                                </div>
                                <h6>Easy and Intuitive Online CV and Resume Builder </h6>
                                <p className='text-secondary'>Lorem ipsum dolor sit amet, consecte adipiscing elit. Nulla neque quam, maxim us ut accumsan
                                    ut, posuere sit Lorem ipsum adipiscing elit.</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                            {/* <!-- Content --> */}
                            <div className="service_single_content text-center mb-100 wow fadeInUp" data-wow-delay="0.5s">
                                {/* <!-- Icon --> */}
                                <div className="service_icon">
                                    <img src={iconF4} alt="" />
                                </div>
                                <h6>Free to use. Developed by hiring professionals.</h6>
                                <p className='text-secondary'>Lorem ipsum dolor sit amet, consecte adipiscing elit. Nulla neque quam, maxim us ut accumsan
                                    ut, posuere sit Lorem ipsum adipiscing elit.</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                            {/* <!-- Content --> */}
                            <div className="service_single_content text-center mb-100 wow fadeInUp" data-wow-delay="0.6s">
                                {/* <!-- Icon --> */}
                                <div className="service_icon">
                                    <img src={iconF5} alt="" />
                                </div>
                                <h6>Recruiter Approved Phrases with Module Notification</h6>
                                <p className='text-secondary'>Lorem ipsum dolor sit amet, consecte adipiscing elit. Nulla neque quam, maxim us ut accumsan
                                    ut, posuere sit Lorem ipsum adipiscing elit.</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4">
                            {/* <!-- Content --> */}
                            <div className="service_single_content text-center mb-100 wow fadeInUp" data-wow-delay="0.7s">
                                {/* <!-- Icon --> */}
                                <div className="service_icon">
                                    <img src={iconF6} alt="" />
                                </div>
                                <h6>Fast Easy CV and Resume Formatting</h6>
                                <p className='text-secondary'>Lorem ipsum dolor sit amet, consecte adipiscing elit. Nulla neque quam, maxim us ut accumsan
                                    ut, posuere sit Lorem ipsum adipiscing elit.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Our features Area End */}

        </>
    )
}
export default Home