import React, { useContext, useState } from 'react';
import './Contact.css';
import axios from 'axios';

// components
import * as serverURL from '../../config/Constants'

// MUI library
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';

const Contact = () => {

    const [error, setError] = React.useState({});
    const [loading, setLoading] = useState(false);
    const [snakbarOpen, setSnakbarOpen] = useState(false);

    // snakbar handlers
    const handleSnackbarClose = () => {
        setSnakbarOpen(false)
    }

    const  handleSubmit =  (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const body = {
            name: data.get('name'),
            email: data.get('email'),
            subject: data.get('subject'),
            message: data.get('message'),
        };

        setLoading(true)

        try {
             axios.post(serverURL.SERVER_URL + 'contact/send', body, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((result) => {
                setSnakbarOpen(true)
                setError(null);
                setLoading(false)
            }).catch(function (error) {
                setError(error.response)
                setLoading(false)
            })

            const form = event.currentTarget;
            const formInputs = form.querySelectorAll('input, textarea');
            formInputs.forEach(input => input.value = '');
        } catch (error) {
        }

    };
    return (
        <>
            {/* ##### Welcome Area Start #####  */}
            <div className="breadcumb-area clearfix dzsparallaxer auto-init" data-options='{direction: "normal"}'>
                <div className="divimage dzsparallaxer--target" style={{ width: '101%', height: '130%', backgroundImage: 'img/bg-img/bg-2.jpg' }}></div>
                {/* breadcumb content  */}
                <div className="breadcumb-content">
                    <div className="container h-100">
                        <div className="row h-100 align-items-center">
                            <div className="col-12">
                                <nav aria-label="breadcrumb" className="breadcumb--con text-center">
                                    <h2 className="title wow fadeInUp text-dark" data-wow-delay="0.2s" >Contact Us</h2>
                                    <ol className="breadcrumb justify-content-center wow fadeInUp" data-wow-delay="0.4s">
                                        <li className="breadcrumb-item "><a href="#" className='text-dark'>Home</a></li>
                                        <li className="breadcrumb-item active text-dark" aria-current="page">About us</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ##### Welcome Area End #####   */}

            {/* ##### Contact Area Start #####  */}
            <section className="section-padding-100 contact_us_area" id="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-heading text-center">
                                {/* Dream Dots  */}
                                <div className="dream-dots justify-content-center wow fadeInUp" data-wow-delay="0.2s">
                                    <span></span><span></span><span></span><span></span><span></span><span></span><span></span>
                                </div>
                                <h2 className="wow fadeInUp" data-wow-delay="0.3s">Contact With Us</h2>
                                <p className="wow fadeInUp text-secondary fw-bold" data-wow-delay="0.4s">We're here to help! Got questions or feedback? Reach out to us anytime. Your satisfaction is important to us. Thanks for considering us!</p>
                            </div>
                        </div>
                    </div>

                    {/* Contact Form  */}
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8">
                            <div className="contact_form">
                                <form action="#" method="post" onSubmit={handleSubmit} >
                                    <div className="row">
                                        <div className="col-12">
                                            <div id="success_fail_info"></div>
                                        </div>
                                        <div className="col-12 col-md-6 mb-2">
                                            <input type="text" name="name" id="name" className={`form-control`} required placeholder='Name' />
                                        </div>
                                        <div className="col-12 col-md-6  mb-2">
                                            <input type="text" name="email" id="email" className={`form-control`} required placeholder='Email' />
                                        </div>
                                        <div className="col-12 mt-lg-3  mb-2">
                                            <input type="text" name="subject" id="subject" className={`form-control`} required placeholder='Subject' />
                                        </div>
                                        <div className="col-12 mt-lg-3  mb-2">
                                            <textarea name="message" rows="6" id="message" className={`form-control`} required placeholder='Message' ></textarea>
                                        </div>
                                        <div className="col-12 text-center mt-lg-3">
                                            <button type="submit" className="btn dream-btn" >
                                                {
                                                    loading ? <span>Sending <CircularProgress size={15} className={'mt-3'} sx={{ color: 'white' }} /></span> : <span>Send Message</span>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Contact Area End  */}

            <Snackbar
                open={snakbarOpen}
                autoHideDuration={1500}
                message="Your message has been sent successfully"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                sx={{ marginBottom: 7 }}
                onClose={handleSnackbarClose}
            />
        </>
    )
}

export default Contact