import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import './Language.css';

import { Link, useNavigate } from 'react-router-dom';
import { Border, ChevronRight, PlugFill, Plus, Trash } from "react-bootstrap-icons";

// components
import * as serverURL from '../../../config/Constants'

// providers
import { useResumeStep } from '../../../context/resume-step/ResumeStepContext';
import { ValidationContext } from '../../../context/validation/ValidationContext';
import { useACVResume } from '../../../context/resume/ResumeContext';

// MUI library
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Rating from '@mui/material/Rating';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Tooltip from '@mui/material/Tooltip';
import Backdrop from '@mui/material/Backdrop';
import { styled } from '@mui/material/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CircleIcon from '@mui/icons-material/Circle';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#fbe8aa',
    },
    '& .MuiRating-iconHover': {
        color: '#fede71',
    },
});


const Language = () => {
    const navigate = useNavigate();
    const { setResumeStep } = useResumeStep();
    const { acvResume, setAcvResume } = useACVResume();
    const [dailogOpen, setDailogOpen] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [error, setError] = useState({});
    const [languageTextboxes, setLanguageTextboxes] = useState([{
        language_name: '',
        rate: ''
    }]);

    // import validtaion context params
    const { validate, setFormErrorEmpty, formError, setFormError } = useContext(ValidationContext);

    const handleAddTextBox = (e) => {
        e.preventDefault();
        const languageSkill = {
            language_name: '',
            rate: 1
        }
        setLanguageTextboxes([...languageTextboxes, languageSkill]);
    };

    const handleRemoveTextBox = (index) => {
        const updatedTextboxes = languageTextboxes.filter((_, i) => i !== index);
        setLanguageTextboxes(updatedTextboxes);
    };

    const handleChange = (index, value) => {
        const updatedTextboxes = [...languageTextboxes];
        updatedTextboxes[index].language_name = value;
        setLanguageTextboxes(updatedTextboxes);
    };
    const handleRateChange = (index, rate) => {
        const updatedTextboxes = [...languageTextboxes];
        updatedTextboxes[index].rate = rate;
        setLanguageTextboxes(updatedTextboxes);
    };

    // close the confirmation dialog 
    const handleDailogClose = () => {
        setDailogOpen(false);
    };


    useEffect(() => {
        if (acvResume) {
            axios.get(serverURL.SERVER_URL + 'languages/' + acvResume.resume_id, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': '$2y$10$e6tbgQSbvmC6X7us57XsIeuBZT7eZYqiu/zDTKjKNX4KL9wQEOvEG'
                }
            }).then((result) => {
                const data = result.data;
                if (data.skills.length > 0) {
                    setLanguageTextboxes(data.skills);
                    console.log(languageTextboxes);
                }
            })
        }
    }, [])

    const cehckLanguageSkills = () => {
        if (languageTextboxes.length < 1) {
            setDailogOpen(true);
        } else {
            nextReferences();
        }
    };

    const nextReferences = () => {

        setDailogOpen(false);
        setBackdropOpen(true);
        try {
            axios.post(serverURL.SERVER_URL + 'languages/' + acvResume.resume_id, { skills: languageTextboxes }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then((result) => {
                const data = result.data;
                if (result.status === 200) {
                    setResumeStep(6);
                    navigate('/resume/references')
                }
            }).catch(function (error) {
                setBackdropOpen(false);
                setError(error.message)
            })
        } catch (error) {
            setBackdropOpen(false);
        }


    };


    return (
        <>
            <div className="main-content float-end col-lg-10 col-12">
                <div className="title">
                    Tell us about your language skills
                </div>
                <div className="sub-title">
                    Write down your language skills.
                </div>

                <div className='container mt-2'>

                    <div className="col-lg-10 col-12">
                        {languageTextboxes.map((languageTextboxe, index) => (
                            <div className='row col-12 d-flex mt-3'>
                                <div className='text-start col-md-4 col-12'>
                                    <input
                                        className={`col-12 form-control`}
                                        type="text"
                                        placeholder="Language Name"
                                        value={languageTextboxe['language_name'] + ''}
                                        onChange={(e) => handleChange(index, e.target.value)}
                                    />
                                </div>
                                <div className='text-start col-md-3 col-9'>
                                    <label htmlFor="" >Level  &nbsp;</label>
                                    <StyledRating
                                        name="customized-color"
                                        value={parseInt(languageTextboxe['rate'])}
                                        precision={1}
                                        onChange={(e) => handleRateChange(index, e.target.value)}
                                        icon={<CircleIcon fontSize="inherit" />}
                                        emptyIcon={<CircleIcon fontSize="inherit" />}
                                    />
                                </div>
                                <div className='col-md-5 col-3 text-lg-start text-sm-end pt-lg-4 pt-md-0 text-dark fs-6 d-flex flex-column justify-content-center' onClick={() => handleRemoveTextBox(index)}>
                                    <Trash />
                                </div>
                            </div>
                        ))}

                        <div className='col-12 text-center  td-none'>
                            <button onClick={handleAddTextBox} className='btn btn-link text-decoration-none'><Plus className='fs-5' /> Add one more</button>
                        </div>
                    </div>

                    <div className='text-end'>
                        <Link to={'/resume/skills'} onClick={() => setResumeStep(4)} className='btn btn-outline-app-primary btn-circle me-1'> Previous</Link>
                        <button className='btn btn-dark btn-circle' onClick={cehckLanguageSkills}> Next : References</button>
                    </div>
                </div>
            </div>

            {/* confirm place order dialog */}
            <Dialog
                open={dailogOpen}
                onClose={handleDailogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Warning, you didn't add your language skills!"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleDailogClose}>Add</Button>
                    <Button onClick={nextReferences}>
                        Containue
                    </Button>
                </DialogActions>
            </Dialog>
            {/* end confirm place order dialog */}

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}

export default Language